
















































import { cardModule } from "@/store/dataModules/cardModule";
import { commonModule } from "@/store/viewModules/commonModule";
import Vue from "vue";
import dayjs from "dayjs";
import { CardResponse } from "gaia-api";
import LocalDataService from "@/service/LocalDataService";
import { userModule } from "@/store/dataModules/userModule";

export default Vue.extend({
  name: "Card",

  props: {
    isLogin: {
      type: String,
    },
  },

  async mounted() {
    await this.getCard();
    await this.getUser();

    // 初回PINコード設定
    if (this.card && !this.card.isPasswordRegistered) {
      this.$router.push({ name: "CardEdit", params: { isLogin: "true" } });
    }
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
    card: function (): CardResponse | null {
      return cardModule.card;
    },
    isForeigner: function (): boolean {
      return userModule.isForeigner;
    },
  },

  methods: {
    async getCard() {
      this.isLoading = true;
      await cardModule.getCard().catch();
      this.isLoading = false;
    },
    async getUser() {
      this.isLoading = true;
      const userId = LocalDataService.getUserId();
      if (userId) {
        await userModule.getUser().catch(() => {
          this.isLoading = false;
        });
      } else {
        this.$router.push({ path: "/auth/signin" });
      }
      this.isLoading = false;
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
  },
});
