



















































import Vue from "vue";
import { commonModule } from "@/store/viewModules/commonModule";
import ApiAuth from "@/api/ApiAuth";
import ValidationMixin from "@/mixins/ValidationMixin";
import LocalDataService from "@/service/LocalDataService";
import { authModule } from "@/store/dataModules/authModule";
export default Vue.extend({
  name: "SmsConfirm",

  mixins: [ValidationMixin],

  data() {
    return {
      input: {
        code: "",
      },
      isFormValid: false,
    };
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
  },

  methods: {
    async confirmCode() {
      this.isLoading = true;
      const sessionToken = localStorage.getItem("ga_session_token");
      const email = localStorage.getItem("ga_email");
      if (sessionToken && email && this.input.code) {
        const response = await ApiAuth.smsConfirm({
          email: email,
          sessionToken: sessionToken,
          code: this.input.code,
        }).catch(() => (this.isLoading = false));
        if (response) {
          LocalDataService.setAccessToken(response.data.accessToken);
          LocalDataService.setRefreshToken(response.data.refreshToken);
          LocalDataService.setUserId(response.data.userId);
          this.$router.push({ name: "User", params: { isLogin: "true" } });
        }
      } else {
        alert("失効しました。再度ログインしてください");
        this.$router.push({ path: "/auth/signin" });
      }
      this.isLoading = false;
    },
    async relogin() {
      const email = localStorage.getItem("ga_email");
      const password = authModule.password;
      this.isLoading = true;
      if (email && password) {
        const response = await ApiAuth.signIn({
          email,
          password,
        }).catch();
        if (response) {
          localStorage.setItem("ga_session_token", response.data.sessionToken);
          alert("再送信しました");
        }
      } else {
        alert("SMSの再送信ができませんでした");
        this.$router.push({ path: "/auth/signin" });
      }
      this.isLoading = false;
    },
  },
});
