



















































import { transactionModule } from "@/store/dataModules/transactionModule";
import { commonModule } from "@/store/viewModules/commonModule";
import { UserTransactionReportListResponse } from "gaia-api";
import ApiTransaction from "@/api/ApiTransaction";
import Vue from "vue";

export default Vue.extend({
  name: "TransactionReport",

  props: {
    isLogin: {
      type: String,
    },
  },

  async mounted() {
    // if (!this.isLogin) {
    //   localStorage.clear();
    //   this.$router.push({ name: 'SignIn' });
    //   return;
    // }

    await this.listTransactionReports();
  },

  data() {
    return {
      quarterlySelect: null,
      yearlySelect: null,
      pdfBtnLoading: false,
    };
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
    transactionReports: function (): UserTransactionReportListResponse | null {
      return transactionModule.transactionReports;
    },
  },

  methods: {
    async listTransactionReports() {
      this.isLoading = true;
      await transactionModule.listTransactionReports().catch();
      this.isLoading = false;
    },
    async onClickQuarter() {
      if (this.quarterlySelect && typeof this.quarterlySelect === "string") {
        this.pdfBtnLoading = true;
        const key = String(this.quarterlySelect);
        const response = await ApiTransaction.listTransactionReports().catch();
        const url = response.data.quarterlyReport[key];
        window.open(url);
        this.pdfBtnLoading = false;
      }
    },
    async onClickYear() {
      if (this.yearlySelect && typeof this.yearlySelect === "string") {
        this.pdfBtnLoading = true;
        const key = String(this.yearlySelect);
        const response = await ApiTransaction.listTransactionReports().catch();
        const url = response.data.yearlyReport[key];
        window.open(url);
        this.pdfBtnLoading = false;
      }
    },
  },
});
