import ApiClient from "@/api/ApiClient";

export default class ApiTransaction {
  // 取引履歴取得
  public static async listTransactions(input: {
    page?: number;
    perPage?: number;
  }) {
    const r = await ApiClient.get(`/transaction/history`, input);
    return r;
  }

  public static async listTransactionReports() {
    const r = await ApiClient.get(`/transaction/report`, null);
    return r;
  }

  // CSVダウンロード
  public static async downloadCsv(input: { page?: number; perPage?: number }) {
    return await ApiClient.get("/transaction/history/download", input);
  }
}
