




import Vue from "vue";

export default Vue.extend({
  name: "Home",

  mounted() {
    this.$router.push({ path: "/user" });
  },
});
