

































































































































































































































































































































































































































import Vue from "vue";
import LocalDataService from "@/service/LocalDataService";
import { PendingPolicieType, userModule } from "@/store/dataModules/userModule";
import { commonModule } from "@/store/viewModules/commonModule";
import dayjs from "dayjs";
import { UserResponse, KycRequestResponse, CardResponse } from "gaia-api";
import { KycRequestStatusCategoryType } from "@API/src/constant/kycRequestStatusCategory";
import { cardModule } from "@/store/dataModules/cardModule";
import Nationalities from "@/service/Nationalities";
import PendingPolicyCard from "@/components/organisms/PendingPolicyCard.vue";

export default Vue.extend({
  name: "User",
  components: {
    PendingPolicyCard,
  },

  props: {
    isLogin: {
      type: String,
    },
  },

  data() {
    return {
      Nationalities: Nationalities,
      dialog: true,
    };
  },

  async mounted() {
    // kyc
    await this.getUser();
    await this.getCard();
    if (this.user && this.card && !this.card.isPasswordRegistered) {
      this.$router.push({ name: "CardEdit", params: { isLogin: "true" } });
    } else if (this.isKycRequired) {
      this.$router.replace({
        name: "RegisterLiquid",
        params: { isLogin: "true" },
      });
    }
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
    user: function (): UserResponse | KycRequestResponse | null {
      if (
        userModule.latestKyc !== null &&
        userModule.latestKyc.requestType === "initialRequest" &&
        (userModule.kycRequestStatus === "initial" ||
          userModule.kycRequestStatus === "inProgress" ||
          userModule.kycRequestStatus === "pendingApproval")
      ) {
        return userModule.latestKyc;
      } else {
        return userModule.user;
      }
    },
    pendingPolicies: (): PendingPolicieType[] => {
      return userModule.pendingPolicies;
    },
    isOpenPendingPolicieDialog: (): boolean => {
      return userModule.pendingPolicies.length > 0;
    },
    reminderDialogStatus: (): boolean => {
      return userModule.isUpdateReminderRequired;
    },
    card: function (): CardResponse | null {
      return cardModule.card;
    },
    isCardExpired: () => {
      if (cardModule.card) {
        if (dayjs().isSame(cardModule.card.cardExpiredAt, "days")) {
          return false;
        } else if (dayjs().isAfter(cardModule.card.cardExpiredAt)) {
          return true;
        }
      }
      return false;
    },
    foreignerAlertExpiredDialogStatus: (): {
      isOpen: boolean;
      isExpierd: boolean;
    } => {
      const isClickisClickNotRightNow = userModule.isClickNotRightNow;
      if (isClickisClickNotRightNow) {
        return { isOpen: false, isExpierd: true };
      }
      const card = cardModule.card;
      if (
        card &&
        card.isCardUpdateRequired
        // && !(
        //   userModule.kycRequestStatus === "initial" ||
        //   userModule.kycRequestStatus === "inProgress" ||
        //   userModule.kycRequestStatus === "pendingApproval"
        // )
      ) {
        if (dayjs().isSame(card.cardExpiredAt, "days")) {
          return { isOpen: true, isExpierd: false };
        } else if (dayjs().isAfter(card.cardExpiredAt)) {
          return { isOpen: true, isExpierd: true };
        } else {
          return { isOpen: true, isExpierd: false };
        }
      } else {
        return { isOpen: false, isExpierd: false };
      }
    },
    kycRequestStatus(): KycRequestStatusCategoryType | null {
      return userModule.kycRequestStatus;
    },
    isKycRequired(): boolean {
      return userModule.isKycRequired;
    },
    isForeigner: function (): boolean {
      return userModule.isForeigner;
    },
  },

  methods: {
    onClickNotRightNow() {
      return userModule.setIsClickNotRightNow(true);
    },
    async getUser() {
      this.isLoading = true;
      const userId = LocalDataService.getUserId();
      if (userId) {
        await userModule.getUser().catch(() => {
          this.isLoading = false;
        });
      } else {
        this.$router.push({ path: "/auth/signin" });
      }
      this.isLoading = false;
    },
    async getCard() {
      this.isLoading = true;
      await cardModule.getCard().catch(() => {
        this.isLoading = false;
      });
      this.isLoading = false;
    },
    formatDate(value: string): string {
      if (!value) {
        return "-";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
    getAssetGradeText(value: number): string {
      if (value == 0) {
        return "０～２５０万円以下";
      } else if (value == 1) {
        return "２５０万円超５００万円以下";
      } else if (value == 2) {
        return "５００万円超７５０万円以下";
      } else if (value == 3) {
        return "７５０万円超１０００万円以下";
      } else if (value == 4) {
        return "１０００万円超";
      } else {
        return "-";
      }
    },
    getTradingAgencies(
      values: { vendor: string; remark?: string }[] | null
    ): string {
      if (!values) {
        return "-";
      }
      return values
        .reduce((a: string[], v) => {
          if (v.vendor && v.vendor !== "") {
            // a.push(`${v.vendor} ${v.remark}\n`);
            a.push(`${v.remark}\n`);
          }
          return a;
        }, [])
        .join();
    },
    getKycStatusText(value: KycRequestStatusCategoryType): string {
      if (value == "initial") {
        return "初回KYC前";
      } else if (value == "inProgress") {
        return "審査中";
      } else if (value == "pendingApproval") {
        return "審査中";
      } else if (value == "rejected") {
        return "否認";
      } else if (value == "verified") {
        return "承認";
      } else if (value == "reSignUpRequired") {
        return "再登録が必要";
      } else if (value == "reUploadRequired") {
        return "身分証の再アップロードが必要";
      } else {
        return "-";
      }
    },
    getNationName(value: string): string {
      return Nationalities.find((n) => n.alpha3 == value)?.companyjp || "";
    },
  },
});
