import ApiClient from "@/api/ApiClient";
import { UpdateMeService, ConfirmPolicyRequestBody } from "gaia-api";

// TODO: 使用しない
export interface UserDetail extends PutUserRequest {
  first_logged_at: string;
  is_locked_until: boolean;
  confirmation_document_url: string;
  created_at: number;
  updated_at: number;
  card: {
    card_id: string;
    card_number: string;
    card_expired_at: number;
  };
  is_peps: boolean;
  is_anti: boolean;
}

// TODO: 使用しない
export interface PutUserRequest {
  user_id: string;
  request_type: "modify" | "renew";
  email: string;
  date_of_birth: string;
  purpose: string;
  phone_number: string;
  commerce_merchandise: string;
  commerce_region: string;
  occupation: string;
  sex: string;
  given_name_kana: string;
  given_name: string;
  family_name_kana: string;
  family_name: string;
  nationality: string;
  residence_end_at: string;
  zip_code: string;
  prefecture: string;
  city: string;
  building: string;
  department: string;
  headquarters_building: string;
  headquarters_city: string;
  headquarters_prefecture: string;
  headquarters_zip_code: string;
  workplace_building: string;
  workplace_city: string;
  workplace_prefecture: string;
  workplace_zip_code: string;
  company_phone_number: string;
  company_name: string;
  trading_agencies: {
    vendor: string;
    remark: string;
  }[];
  total_yens: string;
  total_btc: string;
  total_eth: string;
  total_bch: string;
  total_ltc: string;
  total_xrp: string;
  annual_income: string;
  experience: string[];
  motivation: string[];
  residenceStatus: string;
  is_employment_restriction: boolean;
  employment_restriction: string;
}

export default class ApiUser {
  public static async getUser() {
    const r = await ApiClient.get(`/me`, null);
    return r;
  }

  public static async updateUser(input: Omit<UpdateMeService, "user">) {
    const r = await ApiClient.put(`/me`, input);
    return r;
  }

  public static async confirmPolicy(input: ConfirmPolicyRequestBody) {
    const r = await ApiClient.post(`/policy/confirm`, input);
    return r;
  }
}
