import ApiClient from "@/api/ApiClient";
import { UpdateCardRequestBody } from "gaia-api";

export default class ApiCard {
  public static async getCard() {
    const r = await ApiClient.get(`/card`, null);
    return r;
  }

  public static async updateCard(input: Omit<UpdateCardRequestBody, "user">) {
    const r = await ApiClient.put(`/card`, input);
    return r;
  }
}
