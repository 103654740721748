const Nationalities = [
  {
    companyjp: "アイスランド",
    companyen: "Iceland",
    numeric: 352,
    alpha3: "ISL",
    alpha2: "IS",
    location: "北ヨーロッパ",
    subdivision: "ISO 3166-2:IS",
  },
  {
    companyjp: "アイルランド",
    companyen: "Ireland",
    numeric: 372,
    alpha3: "IRL",
    alpha2: "IE",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:IE",
  },
  {
    companyjp: "アゼルバイジャン",
    companyen: "Azerbaijan",
    numeric: "031",
    alpha3: "AZE",
    alpha2: "AZ",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:AZ",
  },
  {
    companyjp: "アフガニスタン",
    companyen: "Afghanistan",
    numeric: "004",
    alpha3: "AFG",
    alpha2: "AF",
    location: "中東",
    subdivision: "ISO 3166-2:AF",
  },
  {
    companyjp: "アメリカ合衆国",
    companyen: "United States",
    numeric: 840,
    alpha3: "USA",
    alpha2: "US",
    location: "北アメリカ",
    subdivision: "ISO 3166-2:US",
  },
  {
    companyjp: "アメリカ領ヴァージン諸島",
    companyen: "Virgin Islands, U.S.",
    numeric: 850,
    alpha3: "VIR",
    alpha2: "VI",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:VI",
  },
  {
    companyjp: "アメリカ領サモア",
    companyen: "American Samoa",
    numeric: "016",
    alpha3: "ASM",
    alpha2: "AS",
    location: "オセアニア",
    subdivision: "ISO 3166-2:AS",
  },
  {
    companyjp: "アラブ首長国連邦",
    companyen: "United Arab Emirates",
    numeric: 784,
    alpha3: "ARE",
    alpha2: "AE",
    location: "中東",
    subdivision: "ISO 3166-2:AE",
  },
  {
    companyjp: "アルジェリア",
    companyen: "Algeria",
    numeric: "012",
    alpha3: "DZA",
    alpha2: "DZ",
    location: "北アフリカ",
    subdivision: "ISO 3166-2:DZ",
  },
  {
    companyjp: "アルゼンチン",
    companyen: "Argentina",
    numeric: "032",
    alpha3: "ARG",
    alpha2: "AR",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:AR",
  },
  {
    companyjp: "アルバ",
    companyen: "Aruba",
    numeric: 533,
    alpha3: "ABW",
    alpha2: "AW",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:AW",
  },
  {
    companyjp: "アルバニア",
    companyen: "Albania",
    numeric: "008",
    alpha3: "ALB",
    alpha2: "AL",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:AL",
  },
  {
    companyjp: "アルメニア",
    companyen: "Armenia",
    numeric: "051",
    alpha3: "ARM",
    alpha2: "AM",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:AM",
  },
  {
    companyjp: "アンギラ",
    companyen: "Anguilla",
    numeric: 660,
    alpha3: "AIA",
    alpha2: "AI",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:AI",
  },
  {
    companyjp: "アンゴラ",
    companyen: "Angola",
    numeric: "024",
    alpha3: "AGO",
    alpha2: "AO",
    location: "南アフリカ",
    subdivision: "ISO 3166-2:AO",
  },
  {
    companyjp: "アンティグア・バーブーダ",
    companyen: "Antigua and Barbuda",
    numeric: "028",
    alpha3: "ATG",
    alpha2: "AG",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:AG",
  },
  {
    companyjp: "アンドラ",
    companyen: "Andorra",
    numeric: "020",
    alpha3: "AND",
    alpha2: "AD",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:AD",
  },
  {
    companyjp: "イエメン",
    companyen: "Yemen",
    numeric: 887,
    alpha3: "YEM",
    alpha2: "YE",
    location: "中東",
    subdivision: "ISO 3166-2:YE",
  },
  {
    companyjp: "イギリス",
    companyen: "United Kingdom",
    numeric: 826,
    alpha3: "GBR",
    alpha2: "GB",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:GB",
  },
  {
    companyjp: "イギリス領インド洋地域",
    companyen: "British Indian Ocean Territory",
    numeric: "086",
    alpha3: "IOT",
    alpha2: "IO",
    location: "インド洋地域",
    subdivision: "ISO 3166-2:IO",
  },
  {
    companyjp: "イギリス領ヴァージン諸島",
    companyen: "Virgin Islands, British",
    numeric: "092",
    alpha3: "VGB",
    alpha2: "VG",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:VG",
  },
  {
    companyjp: "イスラエル",
    companyen: "Israel",
    numeric: 376,
    alpha3: "ISR",
    alpha2: "IL",
    location: "中東",
    subdivision: "ISO 3166-2:IL",
  },
  {
    companyjp: "イタリア",
    companyen: "Italy",
    numeric: 380,
    alpha3: "ITA",
    alpha2: "IT",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:IT",
  },
  {
    companyjp: "イラク",
    companyen: "Iraq",
    numeric: 368,
    alpha3: "IRQ",
    alpha2: "IQ",
    location: "中東",
    subdivision: "ISO 3166-2:IQ",
  },
  {
    companyjp: "イラン",
    companyen: "Iran, Islamic Republic of",
    numeric: 364,
    alpha3: "IRN",
    alpha2: "IR",
    location: "中東",
    subdivision: "ISO 3166-2:IR",
  },
  {
    companyjp: "インド",
    companyen: "India",
    numeric: 356,
    alpha3: "IND",
    alpha2: "IN",
    location: "南アジア",
    subdivision: "ISO 3166-2:IN",
  },
  {
    companyjp: "インドネシア",
    companyen: "Indonesia",
    numeric: 360,
    alpha3: "IDN",
    alpha2: "ID",
    location: "東南アジア",
    subdivision: "ISO 3166-2:ID",
  },
  {
    companyjp: "ウォリス・フツナ",
    companyen: "Wallis and Futuna",
    numeric: 876,
    alpha3: "WLF",
    alpha2: "WF",
    location: "オセアニア",
    subdivision: "ISO 3166-2:WF",
  },
  {
    companyjp: "ウガンダ",
    companyen: "Uganda",
    numeric: 800,
    alpha3: "UGA",
    alpha2: "UG",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:UG",
  },
  {
    companyjp: "ウクライナ",
    companyen: "Ukraine",
    numeric: 804,
    alpha3: "UKR",
    alpha2: "UA",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:UA",
  },
  {
    companyjp: "ウズベキスタン",
    companyen: "Uzbekistan",
    numeric: 860,
    alpha3: "UZB",
    alpha2: "UZ",
    location: "中央アジア",
    subdivision: "ISO 3166-2:UZ",
  },
  {
    companyjp: "ウルグアイ",
    companyen: "Uruguay",
    numeric: 858,
    alpha3: "URY",
    alpha2: "UY",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:UY",
  },
  {
    companyjp: "エクアドル",
    companyen: "Ecuador",
    numeric: 218,
    alpha3: "ECU",
    alpha2: "EC",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:EC",
  },
  {
    companyjp: "エジプト",
    companyen: "Egypt",
    numeric: 818,
    alpha3: "EGY",
    alpha2: "EG",
    location: "北アフリカ",
    subdivision: "ISO 3166-2:EG",
  },
  {
    companyjp: "エストニア",
    companyen: "Estonia",
    numeric: 233,
    alpha3: "EST",
    alpha2: "EE",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:EE",
  },
  {
    companyjp: "エチオピア",
    companyen: "Ethiopia",
    numeric: 231,
    alpha3: "ETH",
    alpha2: "ET",
    location: "東アフリカ",
    subdivision: "ISO 3166-2:ET",
  },
  {
    companyjp: "エリトリア",
    companyen: "Eritrea",
    numeric: 232,
    alpha3: "ERI",
    alpha2: "ER",
    location: "東アフリカ",
    subdivision: "ISO 3166-2:ER",
  },
  {
    companyjp: "エルサルバドル",
    companyen: "El Salvador",
    numeric: 222,
    alpha3: "SLV",
    alpha2: "SV",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:SV",
  },
  {
    companyjp: "オーストラリア",
    companyen: "Australia",
    numeric: "036",
    alpha3: "AUS",
    alpha2: "AU",
    location: "オセアニア",
    subdivision: "ISO 3166-2:AU",
  },
  {
    companyjp: "オーストリア",
    companyen: "Austria",
    numeric: "040",
    alpha3: "AUT",
    alpha2: "AT",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:AT",
  },
  {
    companyjp: "オーランド諸島",
    companyen: "Åland Islands",
    numeric: 248,
    alpha3: "ALA",
    alpha2: "AX",
    location: "北ヨーロッパ",
    subdivision: "ISO 3166-2:AX",
  },
  {
    companyjp: "オマーン",
    companyen: "Oman",
    numeric: 512,
    alpha3: "OMN",
    alpha2: "OM",
    location: "中東",
    subdivision: "ISO 3166-2:OM",
  },
  {
    companyjp: "オランダ",
    companyen: "Netherlands",
    numeric: 528,
    alpha3: "NLD",
    alpha2: "NL",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:NL",
  },
  {
    companyjp: "ガーナ",
    companyen: "Ghana",
    numeric: 288,
    alpha3: "GHA",
    alpha2: "GH",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:GH",
  },
  {
    companyjp: "カーボベルデ",
    companyen: "Cape Verde",
    numeric: 132,
    alpha3: "CPV",
    alpha2: "CV",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:CV",
  },
  {
    companyjp: "ガーンジー",
    companyen: "Guernsey",
    numeric: 831,
    alpha3: "GGY",
    alpha2: "GG",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:GG",
  },
  {
    companyjp: "ガイアナ",
    companyen: "Guyana",
    numeric: 328,
    alpha3: "GUY",
    alpha2: "GY",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:GY",
  },
  {
    companyjp: "カザフスタン",
    companyen: "Kazakhstan",
    numeric: 398,
    alpha3: "KAZ",
    alpha2: "KZ",
    location: "中央アジア",
    subdivision: "ISO 3166-2:KZ",
  },
  {
    companyjp: "カタール",
    companyen: "Qatar",
    numeric: 634,
    alpha3: "QAT",
    alpha2: "QA",
    location: "中東",
    subdivision: "ISO 3166-2:QA",
  },
  {
    companyjp: "合衆国領有小離島",
    companyen: "United States Minor Outlying Islands",
    numeric: 581,
    alpha3: "UMI",
    alpha2: "UM",
    location: "オセアニア",
    subdivision: "ISO 3166-2:UM",
  },
  {
    companyjp: "カナダ",
    companyen: "Canada",
    numeric: 124,
    alpha3: "CAN",
    alpha2: "CA",
    location: "北アメリカ",
    subdivision: "ISO 3166-2:CA",
  },
  {
    companyjp: "ガボン",
    companyen: "Gabon",
    numeric: 266,
    alpha3: "GAB",
    alpha2: "GA",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:GA",
  },
  {
    companyjp: "カメルーン",
    companyen: "Cameroon",
    numeric: 120,
    alpha3: "CMR",
    alpha2: "CM",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:CM",
  },
  {
    companyjp: "ガンビア",
    companyen: "Gambia",
    numeric: 270,
    alpha3: "GMB",
    alpha2: "GM",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:GM",
  },
  {
    companyjp: "カンボジア",
    companyen: "Cambodia",
    numeric: 116,
    alpha3: "KHM",
    alpha2: "KH",
    location: "東南アジア",
    subdivision: "ISO 3166-2:KH",
  },
  {
    companyjp: "北マリアナ諸島",
    companyen: "Northern Mariana Islands",
    numeric: 580,
    alpha3: "MNP",
    alpha2: "MP",
    location: "オセアニア",
    subdivision: "ISO 3166-2:MP",
  },
  {
    companyjp: "ギニア",
    companyen: "Guinea",
    numeric: 324,
    alpha3: "GIN",
    alpha2: "GN",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:GN",
  },
  {
    companyjp: "ギニアビサウ",
    companyen: "Guinea-Bissau",
    numeric: 624,
    alpha3: "GNB",
    alpha2: "GW",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:GW",
  },
  {
    companyjp: "キプロス",
    companyen: "Cyprus",
    numeric: 196,
    alpha3: "CYP",
    alpha2: "CY",
    location: "地中海地域",
    subdivision: "ISO 3166-2:CY",
  },
  {
    companyjp: "キューバ",
    companyen: "Cuba",
    numeric: 192,
    alpha3: "CUB",
    alpha2: "CU",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:CU",
  },
  {
    companyjp: "キュラソー",
    companyen: "Curaçao",
    numeric: 531,
    alpha3: "CUW",
    alpha2: "CW",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:CW",
  },
  {
    companyjp: "ギリシャ",
    companyen: "Greece",
    numeric: 300,
    alpha3: "GRC",
    alpha2: "GR",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:GR",
  },
  {
    companyjp: "キリバス",
    companyen: "Kiribati",
    numeric: 296,
    alpha3: "KIR",
    alpha2: "KI",
    location: "オセアニア",
    subdivision: "ISO 3166-2:KI",
  },
  {
    companyjp: "キルギス",
    companyen: "Kyrgyzstan",
    numeric: 417,
    alpha3: "KGZ",
    alpha2: "KG",
    location: "中央アジア",
    subdivision: "ISO 3166-2:KG",
  },
  {
    companyjp: "グアテマラ",
    companyen: "Guatemala",
    numeric: 320,
    alpha3: "GTM",
    alpha2: "GT",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:GT",
  },
  {
    companyjp: "グアドループ",
    companyen: "Guadeloupe",
    numeric: 312,
    alpha3: "GLP",
    alpha2: "GP",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:GP",
  },
  {
    companyjp: "グアム",
    companyen: "Guam",
    numeric: 316,
    alpha3: "GUM",
    alpha2: "GU",
    location: "オセアニア",
    subdivision: "ISO 3166-2:GU",
  },
  {
    companyjp: "クウェート",
    companyen: "Kuwait",
    numeric: 414,
    alpha3: "KWT",
    alpha2: "KW",
    location: "中東",
    subdivision: "ISO 3166-2:KW",
  },
  {
    companyjp: "クック諸島",
    companyen: "Cook Islands",
    numeric: 184,
    alpha3: "COK",
    alpha2: "CK",
    location: "オセアニア",
    subdivision: "ISO 3166-2:CK",
  },
  {
    companyjp: "グリーンランド",
    companyen: "Greenland",
    numeric: 304,
    alpha3: "GRL",
    alpha2: "GL",
    location: "北ヨーロッパ",
    subdivision: "ISO 3166-2:GL",
  },
  {
    companyjp: "クリスマス島",
    companyen: "Christmas Island",
    numeric: 162,
    alpha3: "CXR",
    alpha2: "CX",
    location: "オセアニア",
    subdivision: "ISO 3166-2:CX",
  },
  {
    companyjp: "グルジア",
    companyen: "Georgia",
    numeric: 268,
    alpha3: "GEO",
    alpha2: "GE",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:GE",
  },
  {
    companyjp: "グレナダ",
    companyen: "Grenada",
    numeric: 308,
    alpha3: "GRD",
    alpha2: "GD",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:GD",
  },
  {
    companyjp: "クロアチア",
    companyen: "Croatia",
    numeric: 191,
    alpha3: "HRV",
    alpha2: "HR",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:HR",
  },
  {
    companyjp: "ケイマン諸島",
    companyen: "Cayman Islands",
    numeric: 136,
    alpha3: "CYM",
    alpha2: "KY",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:KY",
  },
  {
    companyjp: "ケニア",
    companyen: "Kenya",
    numeric: 404,
    alpha3: "KEN",
    alpha2: "KE",
    location: "東アフリカ",
    subdivision: "ISO 3166-2:KE",
  },
  {
    companyjp: "コートジボワール",
    companyen: "Côte d'Ivoire",
    numeric: 384,
    alpha3: "CIV",
    alpha2: "CI",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:CI",
  },
  {
    companyjp: "ココス諸島",
    companyen: "Cocos (Keeling) Islands",
    numeric: 166,
    alpha3: "CCK",
    alpha2: "CC",
    location: "インド洋地域",
    subdivision: "ISO 3166-2:CC",
  },
  {
    companyjp: "コスタリカ",
    companyen: "Costa Rica",
    numeric: 188,
    alpha3: "CRI",
    alpha2: "CR",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:CR",
  },
  {
    companyjp: "コモロ",
    companyen: "Comoros",
    numeric: 174,
    alpha3: "COM",
    alpha2: "KM",
    location: "インド洋地域",
    subdivision: "ISO 3166-2:KM",
  },
  {
    companyjp: "コロンビア",
    companyen: "Colombia",
    numeric: 170,
    alpha3: "COL",
    alpha2: "CO",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:CO",
  },
  {
    companyjp: "コンゴ共和国",
    companyen: "Congo",
    numeric: 178,
    alpha3: "COG",
    alpha2: "CG",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:CG",
  },
  {
    companyjp: "コンゴ民主共和国",
    companyen: "Congo, the Democratic Republic of the",
    numeric: 180,
    alpha3: "COD",
    alpha2: "CD",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:CD",
  },
  {
    companyjp: "サウジアラビア",
    companyen: "Saudi Arabia",
    numeric: 682,
    alpha3: "SAU",
    alpha2: "SA",
    location: "中東",
    subdivision: "ISO 3166-2:SA",
  },
  {
    companyjp: "サウスジョージア・サウスサンドウィッチ諸島",
    companyen: "South Georgia and the South Sandwich Islands",
    numeric: 239,
    alpha3: "SGS",
    alpha2: "GS",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:GS",
  },
  {
    companyjp: "サモア",
    companyen: "Samoa",
    numeric: 882,
    alpha3: "WSM",
    alpha2: "WS",
    location: "オセアニア",
    subdivision: "ISO 3166-2:WS",
  },
  {
    companyjp: "サントメ・プリンシペ",
    companyen: "Sao Tome and Principe",
    numeric: 678,
    alpha3: "STP",
    alpha2: "ST",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:ST",
  },
  {
    companyjp: "サン・バルテルミー",
    companyen: "Saint Barthélemy",
    numeric: 652,
    alpha3: "BLM",
    alpha2: "BL",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:BL",
  },
  {
    companyjp: "ザンビア",
    companyen: "Zambia",
    numeric: 894,
    alpha3: "ZMB",
    alpha2: "ZM",
    location: "南アフリカ",
    subdivision: "ISO 3166-2:ZM",
  },
  {
    companyjp: "サンピエール島・ミクロン島",
    companyen: "Saint Pierre and Miquelon",
    numeric: 666,
    alpha3: "SPM",
    alpha2: "PM",
    location: "北アメリカ",
    subdivision: "ISO 3166-2:PM",
  },
  {
    companyjp: "サンマリノ",
    companyen: "San Marino",
    numeric: 674,
    alpha3: "SMR",
    alpha2: "SM",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:SM",
  },
  {
    companyjp: "サン・マルタン",
    companyen: "Saint Martin (French part)",
    numeric: 663,
    alpha3: "MAF",
    alpha2: "MF",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:MF",
  },
  {
    companyjp: "シエラレオネ",
    companyen: "Sierra Leone",
    numeric: 694,
    alpha3: "SLE",
    alpha2: "SL",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:SL",
  },
  {
    companyjp: "ジブチ",
    companyen: "Djibouti",
    numeric: 262,
    alpha3: "DJI",
    alpha2: "DJ",
    location: "東アフリカ",
    subdivision: "ISO 3166-2:DJ",
  },
  {
    companyjp: "ジブラルタル",
    companyen: "Gibraltar",
    numeric: 292,
    alpha3: "GIB",
    alpha2: "GI",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:GI",
  },
  {
    companyjp: "ジャージー",
    companyen: "Jersey",
    numeric: 832,
    alpha3: "JEY",
    alpha2: "JE",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:JE",
  },
  {
    companyjp: "ジャマイカ",
    companyen: "Jamaica",
    numeric: 388,
    alpha3: "JAM",
    alpha2: "JM",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:JM",
  },
  {
    companyjp: "シリア",
    companyen: "Syrian Arab Republic",
    numeric: 760,
    alpha3: "SYR",
    alpha2: "SY",
    location: "中東",
    subdivision: "ISO 3166-2:SY",
  },
  {
    companyjp: "シンガポール",
    companyen: "Singapore",
    numeric: 702,
    alpha3: "SGP",
    alpha2: "SG",
    location: "東南アジア",
    subdivision: "ISO 3166-2:SG",
  },
  {
    companyjp: "シント・マールテン",
    companyen: "Sint Maarten (Dutch part)",
    numeric: 534,
    alpha3: "SXM",
    alpha2: "SX",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:SX",
  },
  {
    companyjp: "ジンバブエ",
    companyen: "Zimbabwe",
    numeric: 716,
    alpha3: "ZWE",
    alpha2: "ZW",
    location: "南アフリカ",
    subdivision: "ISO 3166-2:ZW",
  },
  {
    companyjp: "スイス",
    companyen: "Switzerland",
    numeric: 756,
    alpha3: "CHE",
    alpha2: "CH",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:CH",
  },
  {
    companyjp: "スウェーデン",
    companyen: "Sweden",
    numeric: 752,
    alpha3: "SWE",
    alpha2: "SE",
    location: "北ヨーロッパ",
    subdivision: "ISO 3166-2:SE",
  },
  {
    companyjp: "スーダン",
    companyen: "Sudan",
    numeric: 729,
    alpha3: "SDN",
    alpha2: "SD",
    location: "東アフリカ",
    subdivision: "ISO 3166-2:SD",
  },
  {
    companyjp: "スヴァールバル諸島およびヤンマイエン島",
    companyen: "Svalbard and Jan Mayen",
    numeric: 744,
    alpha3: "SJM",
    alpha2: "SJ",
    location: "北ヨーロッパ",
    subdivision: "ISO 3166-2:SJ",
  },
  {
    companyjp: "スペイン",
    companyen: "Spain",
    numeric: 724,
    alpha3: "ESP",
    alpha2: "ES",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:ES",
  },
  {
    companyjp: "スリナム",
    companyen: "Suriname",
    numeric: 740,
    alpha3: "SUR",
    alpha2: "SR",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:SR",
  },
  {
    companyjp: "スリランカ",
    companyen: "Sri Lanka",
    numeric: 144,
    alpha3: "LKA",
    alpha2: "LK",
    location: "南アジア",
    subdivision: "ISO 3166-2:LK",
  },
  {
    companyjp: "スロバキア",
    companyen: "Slovakia",
    numeric: 703,
    alpha3: "SVK",
    alpha2: "SK",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:SK",
  },
  {
    companyjp: "スロベニア",
    companyen: "Slovenia",
    numeric: 705,
    alpha3: "SVN",
    alpha2: "SI",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:SI",
  },
  {
    companyjp: "スワジランド",
    companyen: "Swaziland",
    numeric: 748,
    alpha3: "SWZ",
    alpha2: "SZ",
    location: "南アフリカ",
    subdivision: "ISO 3166-2:SZ",
  },
  {
    companyjp: "セーシェル",
    companyen: "Seychelles",
    numeric: 690,
    alpha3: "SYC",
    alpha2: "SC",
    location: "インド洋地域",
    subdivision: "ISO 3166-2:SC",
  },
  {
    companyjp: "赤道ギニア",
    companyen: "Equatorial Guinea",
    numeric: 226,
    alpha3: "GNQ",
    alpha2: "GQ",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:GQ",
  },
  {
    companyjp: "セネガル",
    companyen: "Senegal",
    numeric: 686,
    alpha3: "SEN",
    alpha2: "SN",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:SN",
  },
  {
    companyjp: "セルビア",
    companyen: "Serbia",
    numeric: 688,
    alpha3: "SRB",
    alpha2: "RS",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:RS",
  },
  {
    companyjp: "セントクリストファー・ネイビス",
    companyen: "Saint Kitts and Nevis",
    numeric: 659,
    alpha3: "KNA",
    alpha2: "KN",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:KN",
  },
  {
    companyjp: "セントビンセント・グレナディーン",
    companyen: "Saint Vincent and the Grenadines",
    numeric: 670,
    alpha3: "VCT",
    alpha2: "VC",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:VC",
  },
  {
    companyjp: "セントヘレナ・アセンションおよびトリスタンダクーニャ",
    companyen: "Saint Helena, Ascension and Tristan da Cunha",
    numeric: 654,
    alpha3: "SHN",
    alpha2: "SH",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:SH",
  },
  {
    companyjp: "セントルシア",
    companyen: "Saint Lucia",
    numeric: 662,
    alpha3: "LCA",
    alpha2: "LC",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:LC",
  },
  {
    companyjp: "ソマリア",
    companyen: "Somalia",
    numeric: 706,
    alpha3: "SOM",
    alpha2: "SO",
    location: "東アフリカ",
    subdivision: "ISO 3166-2:SO",
  },
  {
    companyjp: "ソロモン諸島",
    companyen: "Solomon Islands",
    numeric: "090",
    alpha3: "SLB",
    alpha2: "SB",
    location: "オセアニア",
    subdivision: "ISO 3166-2:SB",
  },
  {
    companyjp: "タークス・カイコス諸島",
    companyen: "Turks and Caicos Islands",
    numeric: 796,
    alpha3: "TCA",
    alpha2: "TC",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:TC",
  },
  {
    companyjp: "タイ",
    companyen: "Thailand",
    numeric: 764,
    alpha3: "THA",
    alpha2: "TH",
    location: "東南アジア",
    subdivision: "ISO 3166-2:TH",
  },
  {
    companyjp: "大韓民国",
    companyen: "Korea, Republic of",
    numeric: 410,
    alpha3: "KOR",
    alpha2: "KR",
    location: "東アジア",
    subdivision: "ISO 3166-2:KR",
  },
  {
    companyjp: "台湾",
    companyen: "Taiwan, Province of China",
    numeric: 158,
    alpha3: "TWN",
    alpha2: "TW",
    location: "東アジア",
    subdivision: "ISO 3166-2:TW",
  },
  {
    companyjp: "タジキスタン",
    companyen: "Tajikistan",
    numeric: 762,
    alpha3: "TJK",
    alpha2: "TJ",
    location: "中央アジア",
    subdivision: "ISO 3166-2:TJ",
  },
  {
    companyjp: "タンザニア",
    companyen: "Tanzania, United Republic of",
    numeric: 834,
    alpha3: "TZA",
    alpha2: "TZ",
    location: "東アフリカ",
    subdivision: "ISO 3166-2:TZ",
  },
  {
    companyjp: "チェコ",
    companyen: "Czech Republic",
    numeric: 203,
    alpha3: "CZE",
    alpha2: "CZ",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:CZ",
  },
  {
    companyjp: "チャド",
    companyen: "Chad",
    numeric: 148,
    alpha3: "TCD",
    alpha2: "TD",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:TD",
  },
  {
    companyjp: "中央アフリカ共和国",
    companyen: "Central African Republic",
    numeric: 140,
    alpha3: "CAF",
    alpha2: "CF",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:CF",
  },
  {
    companyjp: "中国",
    companyen: "China",
    numeric: 156,
    alpha3: "CHN",
    alpha2: "CN",
    location: "東アジア",
    subdivision: "ISO 3166-2:CN",
  },
  {
    companyjp: "チュニジア",
    companyen: "Tunisia",
    numeric: 788,
    alpha3: "TUN",
    alpha2: "TN",
    location: "北アフリカ",
    subdivision: "ISO 3166-2:TN",
  },
  {
    companyjp: "朝鮮民主主義人民共和国",
    companyen: "Korea, Democratic People's Republic of",
    numeric: 408,
    alpha3: "PRK",
    alpha2: "KP",
    location: "東アジア",
    subdivision: "ISO 3166-2:KP",
  },
  {
    companyjp: "チリ",
    companyen: "Chile",
    numeric: 152,
    alpha3: "CHL",
    alpha2: "CL",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:CL",
  },
  {
    companyjp: "ツバル",
    companyen: "Tuvalu",
    numeric: 798,
    alpha3: "TUV",
    alpha2: "TV",
    location: "オセアニア",
    subdivision: "ISO 3166-2:TV",
  },
  {
    companyjp: "デンマーク",
    companyen: "Denmark",
    numeric: 208,
    alpha3: "DNK",
    alpha2: "DK",
    location: "北ヨーロッパ",
    subdivision: "ISO 3166-2:DK",
  },
  {
    companyjp: "ドイツ",
    companyen: "Germany",
    numeric: 276,
    alpha3: "DEU",
    alpha2: "DE",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:DE",
  },
  {
    companyjp: "トーゴ",
    companyen: "Togo",
    numeric: 768,
    alpha3: "TGO",
    alpha2: "TG",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:TG",
  },
  {
    companyjp: "トケラウ",
    companyen: "Tokelau",
    numeric: 772,
    alpha3: "TKL",
    alpha2: "TK",
    location: "オセアニア",
    subdivision: "ISO 3166-2:TK",
  },
  {
    companyjp: "ドミニカ共和国",
    companyen: "Dominican Republic",
    numeric: 214,
    alpha3: "DOM",
    alpha2: "DO",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:DO",
  },
  {
    companyjp: "ドミニカ国",
    companyen: "Dominica",
    numeric: 212,
    alpha3: "DMA",
    alpha2: "DM",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:DM",
  },
  {
    companyjp: "トリニダード・トバゴ",
    companyen: "Trinidad and Tobago",
    numeric: 780,
    alpha3: "TTO",
    alpha2: "TT",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:TT",
  },
  {
    companyjp: "トルクメニスタン",
    companyen: "Turkmenistan",
    numeric: 795,
    alpha3: "TKM",
    alpha2: "TM",
    location: "中央アジア",
    subdivision: "ISO 3166-2:TM",
  },
  {
    companyjp: "トルコ",
    companyen: "Turkey",
    numeric: 792,
    alpha3: "TUR",
    alpha2: "TR",
    location: "中東",
    subdivision: "ISO 3166-2:TR",
  },
  {
    companyjp: "トンガ",
    companyen: "Tonga",
    numeric: 776,
    alpha3: "TON",
    alpha2: "TO",
    location: "オセアニア",
    subdivision: "ISO 3166-2:TO",
  },
  {
    companyjp: "ナイジェリア",
    companyen: "Nigeria",
    numeric: 566,
    alpha3: "NGA",
    alpha2: "NG",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:NG",
  },
  {
    companyjp: "ナウル",
    companyen: "Nauru",
    numeric: 520,
    alpha3: "NRU",
    alpha2: "NR",
    location: "オセアニア",
    subdivision: "ISO 3166-2:NR",
  },
  {
    companyjp: "ナミビア",
    companyen: "Namibia",
    numeric: 516,
    alpha3: "NAM",
    alpha2: "NA",
    location: "南アフリカ",
    subdivision: "ISO 3166-2:NA",
  },
  {
    companyjp: "南極",
    companyen: "Antarctica",
    numeric: "010",
    alpha3: "ATA",
    alpha2: "AQ",
    location: "南極",
    subdivision: "ISO 3166-2:AQ",
  },
  {
    companyjp: "ニウエ",
    companyen: "Niue",
    numeric: 570,
    alpha3: "NIU",
    alpha2: "NU",
    location: "オセアニア",
    subdivision: "ISO 3166-2:NU",
  },
  {
    companyjp: "ニカラグア",
    companyen: "Nicaragua",
    numeric: 558,
    alpha3: "NIC",
    alpha2: "NI",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:NI",
  },
  {
    companyjp: "ニジェール",
    companyen: "Niger",
    numeric: 562,
    alpha3: "NER",
    alpha2: "NE",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:NE",
  },
  {
    companyjp: "日本",
    companyen: "Japan",
    numeric: 392,
    alpha3: "JPN",
    alpha2: "JP",
    location: "東アジア",
    subdivision: "ISO 3166-2:JP",
  },
  {
    companyjp: "西サハラ",
    companyen: "Western Sahara",
    numeric: 732,
    alpha3: "ESH",
    alpha2: "EH",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:EH",
  },
  {
    companyjp: "ニューカレドニア",
    companyen: "New Caledonia",
    numeric: 540,
    alpha3: "NCL",
    alpha2: "NC",
    location: "オセアニア",
    subdivision: "ISO 3166-2:NC",
  },
  {
    companyjp: "ニュージーランド",
    companyen: "New Zealand",
    numeric: 554,
    alpha3: "NZL",
    alpha2: "NZ",
    location: "オセアニア",
    subdivision: "ISO 3166-2:NZ",
  },
  {
    companyjp: "ネパール",
    companyen: "Nepal",
    numeric: 524,
    alpha3: "NPL",
    alpha2: "NP",
    location: "南アジア",
    subdivision: "ISO 3166-2:NP",
  },
  {
    companyjp: "ノーフォーク島",
    companyen: "Norfolk Island",
    numeric: 574,
    alpha3: "NFK",
    alpha2: "NF",
    location: "オセアニア",
    subdivision: "ISO 3166-2:NF",
  },
  {
    companyjp: "ノルウェー",
    companyen: "Norway",
    numeric: 578,
    alpha3: "NOR",
    alpha2: "NO",
    location: "北ヨーロッパ",
    subdivision: "ISO 3166-2:NO",
  },
  {
    companyjp: "ハード島とマクドナルド諸島",
    companyen: "Heard Island and McDonald Islands",
    numeric: 334,
    alpha3: "HMD",
    alpha2: "HM",
    location: "インド洋地域",
    subdivision: "ISO 3166-2:HM",
  },
  {
    companyjp: "バーレーン",
    companyen: "Bahrain",
    numeric: "048",
    alpha3: "BHR",
    alpha2: "BH",
    location: "中東",
    subdivision: "ISO 3166-2:BH",
  },
  {
    companyjp: "ハイチ",
    companyen: "Haiti",
    numeric: 332,
    alpha3: "HTI",
    alpha2: "HT",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:HT",
  },
  {
    companyjp: "パキスタン",
    companyen: "Pakistan",
    numeric: 586,
    alpha3: "PAK",
    alpha2: "PK",
    location: "南アジア",
    subdivision: "ISO 3166-2:PK",
  },
  {
    companyjp: "バチカン",
    companyen: "Holy See (Vatican City State)",
    numeric: 336,
    alpha3: "VAT",
    alpha2: "VA",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:VA",
  },
  {
    companyjp: "パナマ",
    companyen: "Panama",
    numeric: 591,
    alpha3: "PAN",
    alpha2: "PA",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:PA",
  },
  {
    companyjp: "バヌアツ",
    companyen: "Vanuatu",
    numeric: 548,
    alpha3: "VUT",
    alpha2: "VU",
    location: "オセアニア",
    subdivision: "ISO 3166-2:VU",
  },
  {
    companyjp: "バハマ",
    companyen: "Bahamas",
    numeric: "044",
    alpha3: "BHS",
    alpha2: "BS",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:BS",
  },
  {
    companyjp: "パプアニューギニア",
    companyen: "Papua New Guinea",
    numeric: 598,
    alpha3: "PNG",
    alpha2: "PG",
    location: "オセアニア",
    subdivision: "ISO 3166-2:PG",
  },
  {
    companyjp: "バミューダ",
    companyen: "Bermuda",
    numeric: "060",
    alpha3: "BMU",
    alpha2: "BM",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:BM",
  },
  {
    companyjp: "パラオ",
    companyen: "Palau",
    numeric: 585,
    alpha3: "PLW",
    alpha2: "PW",
    location: "オセアニア",
    subdivision: "ISO 3166-2:PW",
  },
  {
    companyjp: "パラグアイ",
    companyen: "Paraguay",
    numeric: 600,
    alpha3: "PRY",
    alpha2: "PY",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:PY",
  },
  {
    companyjp: "バルバドス",
    companyen: "Barbados",
    numeric: "052",
    alpha3: "BRB",
    alpha2: "BB",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:BB",
  },
  {
    companyjp: "パレスチナ",
    companyen: "Palestinian Territory, Occupied",
    numeric: 275,
    alpha3: "PSE",
    alpha2: "PS",
    location: "中東",
    subdivision: "ISO 3166-2:PS",
  },
  {
    companyjp: "ハンガリー",
    companyen: "Hungary",
    numeric: 348,
    alpha3: "HUN",
    alpha2: "HU",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:HU",
  },
  {
    companyjp: "バングラデシュ",
    companyen: "Bangladesh",
    numeric: "050",
    alpha3: "BGD",
    alpha2: "BD",
    location: "南アジア",
    subdivision: "ISO 3166-2:BD",
  },
  {
    companyjp: "東ティモール",
    companyen: "Timor-Leste",
    numeric: 626,
    alpha3: "TLS",
    alpha2: "TL",
    location: "東南アジア",
    subdivision: "ISO 3166-2:TL",
  },
  {
    companyjp: "ピトケアン",
    companyen: "Pitcairn",
    numeric: 612,
    alpha3: "PCN",
    alpha2: "PN",
    location: "オセアニア",
    subdivision: "ISO 3166-2:PN",
  },
  {
    companyjp: "フィジー",
    companyen: "Fiji",
    numeric: 242,
    alpha3: "FJI",
    alpha2: "FJ",
    location: "オセアニア",
    subdivision: "ISO 3166-2:FJ",
  },
  {
    companyjp: "フィリピン",
    companyen: "Philippines",
    numeric: 608,
    alpha3: "PHL",
    alpha2: "PH",
    location: "東南アジア",
    subdivision: "ISO 3166-2:PH",
  },
  {
    companyjp: "フィンランド",
    companyen: "Finland",
    numeric: 246,
    alpha3: "FIN",
    alpha2: "FI",
    location: "北ヨーロッパ",
    subdivision: "ISO 3166-2:FI",
  },
  {
    companyjp: "ブータン",
    companyen: "Bhutan",
    numeric: "064",
    alpha3: "BTN",
    alpha2: "BT",
    location: "南アジア",
    subdivision: "ISO 3166-2:BT",
  },
  {
    companyjp: "ブーベ島",
    companyen: "Bouvet Island",
    numeric: "074",
    alpha3: "BVT",
    alpha2: "BV",
    location: "南極",
    subdivision: "ISO 3166-2:BV",
  },
  {
    companyjp: "プエルトリコ",
    companyen: "Puerto Rico",
    numeric: 630,
    alpha3: "PRI",
    alpha2: "PR",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:PR",
  },
  {
    companyjp: "フェロー諸島",
    companyen: "Faroe Islands",
    numeric: 234,
    alpha3: "FRO",
    alpha2: "FO",
    location: "北ヨーロッパ",
    subdivision: "ISO 3166-2:FO",
  },
  {
    companyjp: "フォークランド諸島",
    companyen: "Falkland Islands (Malvinas)",
    numeric: 238,
    alpha3: "FLK",
    alpha2: "FK",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:FK",
  },
  {
    companyjp: "ブラジル",
    companyen: "Brazil",
    numeric: "076",
    alpha3: "BRA",
    alpha2: "BR",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:BR",
  },
  {
    companyjp: "フランス",
    companyen: "France",
    numeric: 250,
    alpha3: "FRA",
    alpha2: "FR",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:FR",
  },
  {
    companyjp: "フランス領ギアナ",
    companyen: "French Guiana",
    numeric: 254,
    alpha3: "GUF",
    alpha2: "GF",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:GF",
  },
  {
    companyjp: "フランス領ポリネシア",
    companyen: "French Polynesia",
    numeric: 258,
    alpha3: "PYF",
    alpha2: "PF",
    location: "オセアニア",
    subdivision: "ISO 3166-2:PF",
  },
  {
    companyjp: "フランス領南方・南極地域",
    companyen: "French Southern Territories",
    numeric: 260,
    alpha3: "ATF",
    alpha2: "TF",
    location: "インド洋地域",
    subdivision: "ISO 3166-2:TF",
  },
  {
    companyjp: "ブルガリア",
    companyen: "Bulgaria",
    numeric: 100,
    alpha3: "BGR",
    alpha2: "BG",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:BG",
  },
  {
    companyjp: "ブルキナファソ",
    companyen: "Burkina Faso",
    numeric: 854,
    alpha3: "BFA",
    alpha2: "BF",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:BF",
  },
  {
    companyjp: "ブルネイ",
    companyen: "Brunei Darussalam",
    numeric: "096",
    alpha3: "BRN",
    alpha2: "BN",
    location: "東南アジア",
    subdivision: "ISO 3166-2:BN",
  },
  {
    companyjp: "ブルンジ",
    companyen: "Burundi",
    numeric: 108,
    alpha3: "BDI",
    alpha2: "BI",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:BI",
  },
  {
    companyjp: "ベトナム",
    companyen: "Viet Nam",
    numeric: 704,
    alpha3: "VNM",
    alpha2: "VN",
    location: "東南アジア",
    subdivision: "ISO 3166-2:VN",
  },
  {
    companyjp: "ベナン",
    companyen: "Benin",
    numeric: 204,
    alpha3: "BEN",
    alpha2: "BJ",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:BJ",
  },
  {
    companyjp: "ベネズエラ",
    companyen: "Venezuela, Bolivarian Republic of",
    numeric: 862,
    alpha3: "VEN",
    alpha2: "VE",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:VE",
  },
  {
    companyjp: "ベラルーシ",
    companyen: "Belarus",
    numeric: 112,
    alpha3: "BLR",
    alpha2: "BY",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:BY",
  },
  {
    companyjp: "ベリーズ",
    companyen: "Belize",
    numeric: "084",
    alpha3: "BLZ",
    alpha2: "BZ",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:BZ",
  },
  {
    companyjp: "ペルー",
    companyen: "Peru",
    numeric: 604,
    alpha3: "PER",
    alpha2: "PE",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:PE",
  },
  {
    companyjp: "ベルギー",
    companyen: "Belgium",
    numeric: "056",
    alpha3: "BEL",
    alpha2: "BE",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:BE",
  },
  {
    companyjp: "ポーランド",
    companyen: "Poland",
    numeric: 616,
    alpha3: "POL",
    alpha2: "PL",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:PL",
  },
  {
    companyjp: "ボスニア・ヘルツェゴビナ",
    companyen: "Bosnia and Herzegovina",
    numeric: "070",
    alpha3: "BIH",
    alpha2: "BA",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:BA",
  },
  {
    companyjp: "ボツワナ",
    companyen: "Botswana",
    numeric: "072",
    alpha3: "BWA",
    alpha2: "BW",
    location: "南アフリカ",
    subdivision: "ISO 3166-2:BW",
  },
  {
    companyjp: "BES諸島",
    companyen: "Bonaire, Saint Eustatius and Saba",
    numeric: 535,
    alpha3: "BES",
    alpha2: "BQ",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:BQ",
  },
  {
    companyjp: "ボリビア",
    companyen: "Bolivia, Plurinational State of",
    numeric: "068",
    alpha3: "BOL",
    alpha2: "BO",
    location: "南アメリカ",
    subdivision: "ISO 3166-2:BO",
  },
  {
    companyjp: "ポルトガル",
    companyen: "Portugal",
    numeric: 620,
    alpha3: "PRT",
    alpha2: "PT",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:PT",
  },
  {
    companyjp: "香港",
    companyen: "Hong Kong",
    numeric: 344,
    alpha3: "HKG",
    alpha2: "HK",
    location: "東アジア",
    subdivision: "ISO 3166-2:HK",
  },
  {
    companyjp: "ホンジュラス",
    companyen: "Honduras",
    numeric: 340,
    alpha3: "HND",
    alpha2: "HN",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:HN",
  },
  {
    companyjp: "マーシャル諸島",
    companyen: "Marshall Islands",
    numeric: 584,
    alpha3: "MHL",
    alpha2: "MH",
    location: "オセアニア",
    subdivision: "ISO 3166-2:MH",
  },
  {
    companyjp: "マカオ",
    companyen: "Macao",
    numeric: 446,
    alpha3: "MAC",
    alpha2: "MO",
    location: "東アジア",
    subdivision: "ISO 3166-2:MO",
  },
  {
    companyjp: "マケドニア共和国",
    companyen: "Macedonia, the former Yugoslav Republic of",
    numeric: 807,
    alpha3: "MKD",
    alpha2: "MK",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:MK",
  },
  {
    companyjp: "マダガスカル",
    companyen: "Madagascar",
    numeric: 450,
    alpha3: "MDG",
    alpha2: "MG",
    location: "インド洋地域",
    subdivision: "ISO 3166-2:MG",
  },
  {
    companyjp: "マヨット",
    companyen: "Mayotte",
    numeric: 175,
    alpha3: "MYT",
    alpha2: "YT",
    location: "インド洋地域",
    subdivision: "ISO 3166-2:YT",
  },
  {
    companyjp: "マラウイ",
    companyen: "Malawi",
    numeric: 454,
    alpha3: "MWI",
    alpha2: "MW",
    location: "南アフリカ",
    subdivision: "ISO 3166-2:MW",
  },
  {
    companyjp: "マリ",
    companyen: "Mali",
    numeric: 466,
    alpha3: "MLI",
    alpha2: "ML",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:ML",
  },
  {
    companyjp: "マルタ",
    companyen: "Malta",
    numeric: 470,
    alpha3: "MLT",
    alpha2: "MT",
    location: "地中海地域",
    subdivision: "ISO 3166-2:MT",
  },
  {
    companyjp: "マルティニーク",
    companyen: "Martinique",
    numeric: 474,
    alpha3: "MTQ",
    alpha2: "MQ",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:MQ",
  },
  {
    companyjp: "マレーシア",
    companyen: "Malaysia",
    numeric: 458,
    alpha3: "MYS",
    alpha2: "MY",
    location: "東南アジア",
    subdivision: "ISO 3166-2:MY",
  },
  {
    companyjp: "マン島",
    companyen: "Isle of Man",
    numeric: 833,
    alpha3: "IMN",
    alpha2: "IM",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:IM",
  },
  {
    companyjp: "ミクロネシア連邦",
    companyen: "Micronesia, Federated States of",
    numeric: 583,
    alpha3: "FSM",
    alpha2: "FM",
    location: "オセアニア",
    subdivision: "ISO 3166-2:FM",
  },
  {
    companyjp: "南アフリカ",
    companyen: "South Africa",
    numeric: 710,
    alpha3: "ZAF",
    alpha2: "ZA",
    location: "南アフリカ",
    subdivision: "ISO 3166-2:ZA",
  },
  {
    companyjp: "南スーダン",
    companyen: "South Sudan",
    numeric: 728,
    alpha3: "SSD",
    alpha2: "SS",
    location: "東アフリカ",
    subdivision: "ISO 3166-2:SS",
  },
  {
    companyjp: "ミャンマー",
    companyen: "Myanmar",
    numeric: 104,
    alpha3: "MMR",
    alpha2: "MM",
    location: "東南アジア",
    subdivision: "ISO 3166-2:MM",
  },
  {
    companyjp: "メキシコ",
    companyen: "Mexico",
    numeric: 484,
    alpha3: "MEX",
    alpha2: "MX",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:MX",
  },
  {
    companyjp: "モーリシャス",
    companyen: "Mauritius",
    numeric: 480,
    alpha3: "MUS",
    alpha2: "MU",
    location: "南アフリカ",
    subdivision: "ISO 3166-2:MU",
  },
  {
    companyjp: "モーリタニア",
    companyen: "Mauritania",
    numeric: 478,
    alpha3: "MRT",
    alpha2: "MR",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:MR",
  },
  {
    companyjp: "モザンビーク",
    companyen: "Mozambique",
    numeric: 508,
    alpha3: "MOZ",
    alpha2: "MZ",
    location: "南アフリカ",
    subdivision: "ISO 3166-2:MZ",
  },
  {
    companyjp: "モナコ",
    companyen: "Monaco",
    numeric: 492,
    alpha3: "MCO",
    alpha2: "MC",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:MC",
  },
  {
    companyjp: "モルディブ",
    companyen: "Maldives",
    numeric: 462,
    alpha3: "MDV",
    alpha2: "MV",
    location: "インド洋地域",
    subdivision: "ISO 3166-2:MV",
  },
  {
    companyjp: "モルドバ",
    companyen: "Moldova, Republic of",
    numeric: 498,
    alpha3: "MDA",
    alpha2: "MD",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:MD",
  },
  {
    companyjp: "モロッコ",
    companyen: "Morocco",
    numeric: 504,
    alpha3: "MAR",
    alpha2: "MA",
    location: "北アフリカ",
    subdivision: "ISO 3166-2:MA",
  },
  {
    companyjp: "モンゴル",
    companyen: "Mongolia",
    numeric: 496,
    alpha3: "MNG",
    alpha2: "MN",
    location: "東アジア",
    subdivision: "ISO 3166-2:MN",
  },
  {
    companyjp: "モンテネグロ",
    companyen: "Montenegro",
    numeric: 499,
    alpha3: "MNE",
    alpha2: "ME",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:ME",
  },
  {
    companyjp: "モントセラト",
    companyen: "Montserrat",
    numeric: 500,
    alpha3: "MSR",
    alpha2: "MS",
    location: "中央アメリカ",
    subdivision: "ISO 3166-2:MS",
  },
  {
    companyjp: "ヨルダン",
    companyen: "Jordan",
    numeric: 400,
    alpha3: "JOR",
    alpha2: "JO",
    location: "中東",
    subdivision: "ISO 3166-2:JO",
  },
  {
    companyjp: "ラオス",
    companyen: "Lao People's Democratic Republic",
    numeric: 418,
    alpha3: "LAO",
    alpha2: "LA",
    location: "東南アジア",
    subdivision: "ISO 3166-2:LA",
  },
  {
    companyjp: "ラトビア",
    companyen: "Latvia",
    numeric: 428,
    alpha3: "LVA",
    alpha2: "LV",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:LV",
  },
  {
    companyjp: "リトアニア",
    companyen: "Lithuania",
    numeric: 440,
    alpha3: "LTU",
    alpha2: "LT",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:LT",
  },
  {
    companyjp: "リビア",
    companyen: "Libya",
    numeric: 434,
    alpha3: "LBY",
    alpha2: "LY",
    location: "北アフリカ",
    subdivision: "ISO 3166-2:LY",
  },
  {
    companyjp: "リヒテンシュタイン",
    companyen: "Liechtenstein",
    numeric: 438,
    alpha3: "LIE",
    alpha2: "LI",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:LI",
  },
  {
    companyjp: "リベリア",
    companyen: "Liberia",
    numeric: 430,
    alpha3: "LBR",
    alpha2: "LR",
    location: "西アフリカ",
    subdivision: "ISO 3166-2:LR",
  },
  {
    companyjp: "ルーマニア",
    companyen: "Romania",
    numeric: 642,
    alpha3: "ROU",
    alpha2: "RO",
    location: "東ヨーロッパ",
    subdivision: "ISO 3166-2:RO",
  },
  {
    companyjp: "ルクセンブルク",
    companyen: "Luxembourg",
    numeric: 442,
    alpha3: "LUX",
    alpha2: "LU",
    location: "西ヨーロッパ",
    subdivision: "ISO 3166-2:LU",
  },
  {
    companyjp: "ルワンダ",
    companyen: "Rwanda",
    numeric: 646,
    alpha3: "RWA",
    alpha2: "RW",
    location: "中央アフリカ",
    subdivision: "ISO 3166-2:RW",
  },
  {
    companyjp: "レソト",
    companyen: "Lesotho",
    numeric: 426,
    alpha3: "LSO",
    alpha2: "LS",
    location: "南アフリカ",
    subdivision: "ISO 3166-2:LS",
  },
  {
    companyjp: "レバノン",
    companyen: "Lebanon",
    numeric: 422,
    alpha3: "LBN",
    alpha2: "LB",
    location: "中東",
    subdivision: "ISO 3166-2:LB",
  },
  {
    companyjp: "レユニオン",
    companyen: "Réunion",
    numeric: 638,
    alpha3: "REU",
    alpha2: "RE",
    location: "インド洋地域",
    subdivision: "ISO 3166-2:RE",
  },
  {
    companyjp: "ロシア",
    companyen: "Russian Federation",
    numeric: 643,
    alpha3: "RUS",
    alpha2: "RU",
    location: "ロシア",
    subdivision: "ISO 3166-2:RU",
  },
];

export default Nationalities;
