









































import ApiUser from "@/api/ApiUser";
import { PendingPolicieType, userModule } from "@/store/dataModules/userModule";
import Vue from "vue";
export default Vue.extend({
  name: "PendingPolicyCard",

  data(): {
    selected: string[];
    clickFlagsIndexes: number[];
    isLoading: boolean;
  } {
    return {
      selected: [],
      clickFlagsIndexes: [],
      isLoading: false,
    };
  },

  computed: {
    pendingPolicies: (): PendingPolicieType[] => {
      return userModule.pendingPolicies;
    },
  },

  methods: {
    onOpenPolicy(pdfUrl: string, index: number) {
      window.open(pdfUrl);
      this.clickFlagsIndexes = [...this.clickFlagsIndexes, index];
    },
    isOpenPolicy(index: number) {
      return this.clickFlagsIndexes.includes(index);
    },
    async onClickAgreement() {
      this.isLoading = true;
      await ApiUser.confirmPolicy({
        confirmPolicies: this.selected.map((policyId) => {
          return { policyId };
        }),
      });
      window.location.reload();
      this.isLoading = false;
    },
  },
});
