import Vue from "vue";

export default Vue.extend({
  name: "validation-mixin",

  methods: {
    required(text: string): string | boolean {
      return !!text || "必須";
    },
    isCardNumber(text: string): boolean {
      return text != "" && text.length == 16;
    },
    isEmail(text: string): boolean {
      if (!text) {
        return true;
      }
      return (
        text.match(
          "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
        ) !== null
      );
    },
    isSmsCode(text: string): boolean {
      return text != "" && text.length == 6;
    },
    isCardPincode(text: string): boolean {
      return text != "" && text.length == 4;
    },
    isNumeral(text: string): boolean {
      if (!text) {
        return true;
      }
      return String(text).match(/[+-]?(?:\d+\.?\d*|\.\d+)/) !== null;
      // return String(text).match(/^\d+$/) !== null;
    },
  },
});
