



































import Vue from "vue";
import { kycModule } from "@/store/dataModules/kycModule";
import { userModule } from "@/store/dataModules/userModule";
import { UserResponse } from "gaia-api";
import { KycRequestStatusCategoryType } from "gaia-api/src/constant/kycRequestStatusCategory";

export default Vue.extend({
  name: "RegisterLiquid",

  data() {
    return {
      btnLoading: false,
      applicantUrl: "",
    };
  },

  computed: {
    kycStatus: function (): KycRequestStatusCategoryType | null {
      return userModule.kycRequestStatus;
    },
    user: function (): UserResponse | null {
      return userModule.user;
    },
    isKycRequired(): boolean {
      return userModule.isKycRequired;
    },
  },

  methods: {
    async openKyc() {
      this.btnLoading = true;
      const response = await kycModule.postKyc();
      this.applicantUrl = response.applicantUrl;
      // window.open(this.applicantUrl);
      location.href = this.applicantUrl;
      this.btnLoading = false;
    },
    async getUser() {
      await userModule.getUser().catch();
    },
    goUser() {
      this.$router.push({ name: "User", params: { isLogin: "true" } });
    },
  },

  async mounted() {
    await this.getUser();
    // if (this.user == null && this.isKycRequired) {
    //   await this.openKyc();
    // } else if (!this.isKycRequired && this.user !== null) {
    //   this.goUser();
    // }
    // if (this.isKycRequired) {
    //   await this.openKyc();
    // }
  },
});
