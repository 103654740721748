const KEY_ACCESS_TOKEN = "ga_user_at";
const KEY_REFRESH_TOKEN = "ga_user_rt";
const KEY_USER_ID = "ga_user_userid";

export default class LocalDataService {
  public static setAccessToken(accessToken: string) {
    localStorage.setItem(`${KEY_ACCESS_TOKEN}`, accessToken);
    return accessToken;
  }

  public static getAccessToken(): string | null {
    const saved = localStorage.getItem(`${KEY_ACCESS_TOKEN}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static setRefreshToken(accessToken: string) {
    localStorage.setItem(`${KEY_REFRESH_TOKEN}`, accessToken);
    return accessToken;
  }

  public static getRefreshToken(): string | null {
    const saved = localStorage.getItem(`${KEY_REFRESH_TOKEN}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static setUserId(value: string) {
    localStorage.setItem(`${KEY_USER_ID}`, value);
    return value;
  }

  public static getUserId(): string | null {
    const saved = localStorage.getItem(`${KEY_USER_ID}`);
    if (saved) {
      return saved;
    }
    return null;
  }
}
