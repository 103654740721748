import axios from "axios";
import querystring from "query-string";
import LocalDataService from "@/service/LocalDataService";

/* eslint-disable */

let BASE_URL = "";
let API_KEY = "";
const hostname = location.hostname;
const env = hostname.split(".")[0];
if (env == "localhost" || env == "dev") {
  BASE_URL = "https://dev-api.gaiacrypto.com/app/users"; // dev
  API_KEY = "mjkHZFyMZO8FPpwUin70f98Rsit43EWp2N2f4fVF"; // dev
} else if (env == "stg") {
  BASE_URL = "https://stg-api.gaiacrypto.com/app/users"; // stg
  API_KEY = "hsCBsMAh8x9xQIqd59M0t46scWid4tTZ7R0hl2fq"; // stg
} else if (env == "stg-gaia") {
  BASE_URL = "https://stg-gaia-api.gaiacrypto.com/app/users"; // stg-gaia
  API_KEY = "ObVQVMFelga1tKEzrDSMm3h9qFGF9MHd6Ajn909m"; // stg-gaia
} else {
  BASE_URL = "https://api.gaiacrypto.com/app/users"; // prod
  API_KEY = "AiObQNsKKQ1bl5C6hdc2A58g8xXMElwh763hDgbK"; // prod
}

const path = {
  signIn: "/auth/signin",
};

const getHeaders = async () => {
  const token = LocalDataService.getAccessToken();
  return {
    Authorization: `Bearer ${token}`,
    "x-api-key": `${API_KEY}`,
    "Content-Type": "application/json; charset=utf-8",
  };
};

axios.interceptors.response.use(
  (config) => {
    // Error
    if (config.data.status != null && config.data.status != 0) {
      // アクセストークン失効/不正
      if (config.data.status == "E002" || config.data.status == "E003") {
        alert(config.data.data.message);
        location.href = path.signIn;
        return Promise.reject(config);
      }
      alert(config.data.data.message);
      return Promise.reject(config);
    }
    return config;
  },
  (error) => {
    if (error.response.status === 403 || error.response.status === "403") {
      localStorage.clear();
      location.href = path.signIn;
      return Promise.reject(error);
    }
    // Error
    if (error.response.data.status != null && error.response.data.status != 0) {
      // アクセストークン失効/不正
      if (
        error.response.data.status == "E002" ||
        error.response.data.status == "E003" ||
        error.response.data.status == "E019"
      ) {
        alert(error.response.data.data.message);
        localStorage.clear();
        location.href = path.signIn;
        return Promise.reject(error);
      }
      alert(error.response.data.data.message);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    // console.log("Request", config);
    return config;
  },
  (error) => {
    // console.log("error request in apiclient", error.response);
    return Promise.reject(error);
  }
);

export default class ApiClient {
  public static async get(
    endpoint: string,
    params: { [key: string]: any } | null
  ) {
    if (params) {
      try {
        const response = await axios.get(
          `${BASE_URL}${endpoint}?${querystring.stringify(params, {
            skipNull: true,
            skipEmptyString: true,
          })}`,
          {
            headers: await getHeaders(),
            data: {},
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    } else {
      try {
        const response = await axios.get(`${BASE_URL}${endpoint}`, {
          headers: await getHeaders(),
          data: {},
        });
        return response.data;
      } catch (error: any) {
        if (error.data && error.data.error) {
          throw error.data;
        } else {
          throw error;
        }
      }
    }
  }

  public static async post(
    endpoint: string,
    params: { [key: string]: any } | null,
    isAuth = true
  ) {
    // const token = await auth0Module.getAuth0Token();
    const token = LocalDataService.getAccessToken();
    let headers: { [key: string]: string };
    if (isAuth) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-api-key": `${API_KEY}`,
      };
    } else {
      headers = { "Content-Type": "application/json" };
    }
    return axios
      .post(`${BASE_URL}${endpoint}`, JSON.stringify(params), { headers })
      .then((response) => response.data)
      .catch((e) => {
        console.warn(e);
        throw e;
        // return
      });
  }

  public static async put(
    endpoint: string,
    params: { [key: string]: any } | null
  ) {
    // const token = await auth0Module.getAuth0Token();
    const token = LocalDataService.getAccessToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-api-key": `${API_KEY}`,
    };
    if (params) {
      return axios
        .put(`${BASE_URL}${endpoint}`, JSON.stringify(params), { headers })
        .then((response) => response.data)
        .catch((e) => {
          console.warn(e);
          throw e;
        });
    } else {
      return axios
        .put(`${BASE_URL}${endpoint}`, { headers })
        .then((response) => response.data)
        .catch((e) => {
          console.warn(e);
          throw e;
        });
    }
  }

  public static async delete(
    endpoint: string,
    params: { [key: string]: any } | null
  ) {
    // const token = await auth0Module.getAuth0Token();
    const token = LocalDataService.getAccessToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-api-key": `${API_KEY}`,
    };
    if (params) {
      return axios
        .delete(
          `${BASE_URL}${endpoint}?${querystring.stringify(params, {
            skipNull: true,
          })}`,
          {
            headers,
            data: {},
          }
        )
        .then((response) => response.data);
    } else {
      return axios
        .delete(`${BASE_URL}${endpoint}`, {
          headers,
          data: {},
        })
        .then((response) => response.data);
    }
  }
}
