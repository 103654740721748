const StatusOfResidence = [
  {
    text: '外交',
    value: '外交'
  },
  {
    text: '公用',
    value: '公用'
  },
  {
    text: '教授',
    value: '教授'
  },
  {
    text: '芸術',
    value: '芸術'
  },
  {
    text: '宗教',
    value: '宗教'
  },
  {
    text: '報道',
    value: '報道'
  },
  {
    text: '高度専門職１号イ',
    value: '高度専門職１号イ'
  },
  {
    text: '高度専門職１号ロ',
    value: '高度専門職１号ロ'
  },
  {
    text: '高度専門職１号ハ',
    value: '高度専門職１号ハ'
  },
  {
    text: '高度専門職２号',
    value: '高度専門職２号'
  },
  {
    text: '経営・管理',
    value: '経営・管理'
  },
  {
    text: '法律・会計業務',
    value: '法律・会計業務'
  },
  {
    text: '医療',
    value: '医療'
  },
  {
    text: '研究',
    value: '研究'
  },
  {
    text: '教育',
    value: '教育'
  },
  {
    text: '技術・人文知識・国際業務',
    value: '技術・人文知識・国際業務'
  },
  {
    text: '企業内転勤',
    value: '企業内転勤'
  },
  {
    text: '介護',
    value: '介護'
  },
  {
    text: '興行',
    value: '興行'
  },
  {
    text: '技能',
    value: '技能'
  },
  {
    text: '特定技能１号',
    value: '特定技能１号'
  },
  {
    text: '特定技能２号',
    value: '特定技能２号'
  },
  {
    text: '技能実習１号イ',
    value: '技能実習１号イ'
  },
  {
    text: '技能実習１号ロ',
    value: '技能実習１号ロ'
  },
  {
    text: '技能実習２号イ',
    value: '技能実習２号イ'
  },
  {
    text: '技能実習２号ロ',
    value: '技能実習２号ロ'
  },
  {
    text: '技能実習３号イ',
    value: '技能実習３号イ'
  },
  {
    text: '技能実習３号ロ',
    value: '技能実習３号ロ'
  },
  {
    text: '文化活動',
    value: '文化活動'
  },
  {
    text: '短期滞在',
    value: '短期滞在'
  },
  {
    text: '留学',
    value: '留学'
  },
  {
    text: '研修',
    value: '研修'
  },
  {
    text: '家族滞在',
    value: '家族滞在'
  },
  {
    text: '特定活動',
    value: '特定活動'
  },
  {
    text: '永住者',
    value: '永住者'
  },
  {
    text: '日本人の配偶者等',
    value: '日本人の配偶者等'
  },
  {
    text: '永住者の配偶者等',
    value: '永住者の配偶者等'
  },
  {
    text: '定住者',
    value: '定住者'
  },
  {
    text: '特別永住者',
    value: '特別永住者'
  }
];

export default StatusOfResidence;
