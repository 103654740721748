import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiWallet from "@/api/ApiWallet";
import { UserWalletListResponse, UserWalletRequestBody } from "gaia-api";

// interface WalletEntity {
//   Wallet_id: string;
//   Wallet_number: string;
//   expiration: number;
//   is_password_registered: boolean;
// }

// export interface WalletResponse {
//   new_Wallet: WalletEntity;
//   old_Wallet: WalletEntity;
// }

export interface WalletState {
  listWallet: UserWalletListResponse;
}

@Module({ dynamic: true, store, name: "Wallet", namespaced: true })
class WalletModule extends VuexModule implements WalletState {
  public listWallet: UserWalletListResponse = [];

  @Action({ rawError: true })
  public async getWallet() {
    const response = await ApiWallet.listWallet().catch();
    if (response) {
      this.setWallet(response.data);
      return response;
    }
  }

  @Action({ rawError: true })
  public async postWallet(wallet: UserWalletRequestBody) {
    const response = await ApiWallet.postWallet(wallet).catch((e) => console.warn(e));
    if (response) {
      return response.data
    }
  }

  @Mutation
  public setWallet(value: UserWalletListResponse) {
    this.listWallet = value;
  }
}

export const walletModule = getModule(WalletModule);
