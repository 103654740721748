import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index'

export interface CommonState {
  isLoading: boolean;
}

@Module({ dynamic: true, store, name: 'common', namespaced: true })
class CommonModule extends VuexModule implements CommonState {
  public isLoading = false;

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }
}

export const commonModule = getModule(CommonModule);