



















































import Vue from "vue";
import { commonModule } from "@/store/viewModules/commonModule";
import ApiAuth from "@/api/ApiAuth";
import ValidationMixin from "@/mixins/ValidationMixin";

export default Vue.extend({
  name: "ChangeEmailConfirm",

  mixins: [ValidationMixin],

  mounted() {
    if (this.$route.query && this.$route.query.email) {
      this.input.email = String(this.$route.query.email);
    }
    if (this.$route.query && this.$route.query.token) {
      this.input.token = String(this.$route.query.token);
    }
  },

  data() {
    return {
      input: {
        email: "",
        code: "",
        token: "",
      },
      isFormValid: false,
    };
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
  },

  methods: {
    async changeEmail() {
      this.isLoading = true;
      if (this.input.email && this.input.code && this.input.token) {
        const response = await ApiAuth.confirmChangeEmail({
          email: this.input.email,
          code: this.input.code,
          token: this.input.token,
        }).catch();
        if (response) {
          alert("メールアドレスを変更しました。ログイン画面に戻ります");
          localStorage.clear();
          this.$router.push({ path: "/auth/signin" });
        }
      }
      this.isLoading = false;
    },
  },
});
