import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiCard from "@/api/ApiCard.ts";
import { CardResponse, CardDetailResponse } from "gaia-api";

// interface CardEntity {
//   card_id: string;
//   card_number: string;
//   expiration: number;
//   is_password_registered: boolean;
// }

// export interface CardResponse {
//   new_card: CardEntity;
//   old_card: CardEntity;
// }

export interface CardState {
  card: CardResponse | null;
}

@Module({ dynamic: true, store, name: "card", namespaced: true })
class CardModule extends VuexModule implements CardState {
  public card: CardResponse | null = null;

  @Action({ rawError: true })
  public async getCard() {
    const response = await ApiCard.getCard().catch();
    if (response) {
      this.setCard(response.data);
      return response;
    }
  }

  @Mutation
  public setCard(value: CardDetailResponse) {
    this.card = value.card;
  }
}

export const cardModule = getModule(CardModule);
