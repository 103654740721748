import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiUser from "@/api/ApiUser";
import { UserResponse, KycRequestResponse } from "gaia-api";
import { KycRequestStatusCategoryType } from "@API/src/constant/kycRequestStatusCategory";

export interface PendingPolicieType {
  policyId: string
  name: string
  pdfUrl: string
}

export interface UserState {
  user: UserResponse | null;
  latestKyc: KycRequestResponse | null;
  kycRequestStatus: KycRequestStatusCategoryType | null;
  isKycRequired: boolean;
  pendingPolicies: PendingPolicieType[]
}

@Module({ dynamic: true, store, name: "user", namespaced: true })
class UserModule extends VuexModule implements UserState {
  public user: UserResponse | null = null;
  public latestKyc: KycRequestResponse | null = null;
  public kycRequestStatus: KycRequestStatusCategoryType | null = null;
  public isKycRequired = false;
  public pendingPolicies: PendingPolicieType[] = [];
  public isUpdateReminderRequired = false;
  public isClickNotRightNow = false

  public get isForeigner(): boolean {
    if (this.user) {
      if (this.user.nationality !== "JPN") {
        return this.user.residenceStatus !== "特別永住者" && this.user.residenceStatus !== "永住者"
      } else {
        return false
      }
    } else if (this.latestKyc) {
      if (this.latestKyc.nationality !== "JPN") {
        return this.latestKyc.residenceStatus !== "特別永住者" && this.latestKyc.residenceStatus !== "永住者"
      } else {
        return false
      }
    } else {
      return false
    }
  }

  @Action({ rawError: true })
  public async getUser() {
    const response = await ApiUser.getUser()
      .catch((e) => console.warn(e));
    if (response) {
      this.setUser(response.data.user);
      this.setLatestKyc(response.data.latestKyc);
      this.setKycStatus(response.data.kycRequestStatus);
      this.setIsKycRequired(response.data.isKycRequired);
      this.setPendingPolicies(response.data.pendingPolicies);
      this.setIsUpdateReminderRequired(response.data.isUpdateReminderRequired);
      return response;
    }
  }

  @Mutation
  public setUser(value: UserResponse) {
    this.user = value;
  }

  @Mutation
  public setLatestKyc(value: KycRequestResponse) {
    this.latestKyc = value;
  }

  @Mutation
  public setKycStatus(value: KycRequestStatusCategoryType) {
    this.kycRequestStatus = value;
  }

  @Mutation
  public setIsKycRequired(value: boolean) {
    this.isKycRequired = value;
  }

  @Mutation
  public setPendingPolicies(value: PendingPolicieType[]) {
    this.pendingPolicies = value;
  }

  @Mutation
  public setIsUpdateReminderRequired(value: boolean) {
    this.isUpdateReminderRequired = value;
  }

  @Mutation
  public setIsClickNotRightNow(value: boolean) {
    this.isClickNotRightNow = value;
  }
}

export const userModule = getModule(UserModule);
