import ApiClient from "@/api/ApiClient";
import {
  ChangePasswordService,
  ConfirmForgotPasswordAuthUserService,
  ForgotPasswordAuthUserService,
} from "gaia-api";

export default class ApiPassword {
  public static async forgotPassword(input: ForgotPasswordAuthUserService) {
    const r = await ApiClient.post("/auth/forgot_password", input);
    return r;
  }

  public static async confirmForgotPassword(
    input: ConfirmForgotPasswordAuthUserService
  ) {
    const r = await ApiClient.put("/auth/forgot_password/confirmation", input);
    return r;
  }

  public static async changePassword(
    input: Omit<ChangePasswordService, "accessToken" | "user">
  ) {
    const r = await ApiClient.put("/change_password", input);
    return r;
  }
}
