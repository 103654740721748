import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiKyc from "@/api/ApiKyc";
import { UserKycResponse } from 'gaia-api';

export interface KycState {
  kycRequestStatus: string | null;
}

@Module({ dynamic: true, store, name: "kyc", namespaced: true })
class KycModule extends VuexModule implements KycState {
  public kycRequestStatus: string | null = null;

  @Action({ rawError: true })
  public async postKyc() {
    const response = await ApiKyc.createKycRequest()
      .catch((e) => console.warn(e));
    if (response) {
      this.setKyc(response.data);
      return response.data
    }
  }

  @Mutation
  public setKyc(value: UserKycResponse) {
    this.kycRequestStatus = value.kycRequestStatus;
  }
}

export const kycModule = getModule(KycModule);
