const EmploymentRestrictions = [
  // {
  //   text: '無',
  //   value: ''
  // },
  {
    text: '在留資格に基づく就労活動のみ可',
    value: '在留資格に基づく就労活動のみ可'
  },
  {
    text: '就労不可',
    value: '就労不可'
  },
  {
    text: '指定書により指定された就労活動のみ可（特定活動）',
    value: '指定書により指定された就労活動のみ可（特定活動）'
  },
  {
    text: '指定書記載機関での在留資格に基づく就労活動のみ可（技能実習）',
    value: '指定書記載機関での在留資格に基づく就労活動のみ可（技能実習）'
  }
];

export default EmploymentRestrictions;
