








































import Vue from "vue";
import { commonModule } from "@/store/viewModules/commonModule";
import ApiAuth from "@/api/ApiAuth";
import ValidationMixin from "@/mixins/ValidationMixin";

export default Vue.extend({
  name: "ChangeEmail",

  mixins: [ValidationMixin],

  props: {
    isLogin: {
      type: String,
    },
  },

  data() {
    return {
      input: {
        email: "",
      },
      isFormValid: false,
    };
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
  },

  methods: {
    async sendEmail() {
      this.isLoading = true;
      const response = await ApiAuth.changeEmail({
        email: this.input.email,
      }).catch();
      if (response) {
        alert("メールを送信しました");
        this.$router.push({
          path: "/auth/change-email/confirm",
          query: {
            email: this.input.email,
            token: response.data.token,
          },
        });
      }
      this.isLoading = false;
    },
  },
});
