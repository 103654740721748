












































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import LocalDataService from "@/service/LocalDataService";
import { userModule } from "@/store/dataModules/userModule";
import { commonModule } from "@/store/viewModules/commonModule";
import ApiUser from "@/api/ApiUser";
import dayjs from "dayjs";
import Nationalities from "@/service/Nationalities";
import { UserResponse, CardResponse } from "gaia-api";
import { KycRequestCategoryType } from "gaia-api/src/constant/kycRequestCategory";
import { cardModule } from "@/store/dataModules/cardModule";
import ValidationMixin from "@/mixins/ValidationMixin";
import StatusOfResidence from "@/service/StatusOfResidence";
import EmploymentRestrictions from "@/service/EmployeeRestrictions";

export default Vue.extend({
  name: "UserEdit",

  mixins: [ValidationMixin],

  props: {
    isLogin: {
      type: String,
    },
    editType: {
      type: String, // reminderJapan reminderForeigner default
    },
  },

  async mounted() {
    await this.getCard();
    await this.getUser();
  },

  data() {
    return {
      isFormValid: false,
      zipCodeValues: {
        zip1: "",
        zip2: "",
      },
      inputValues: {
        /** user */
        familyName: "",
        familyNameKana: "",
        givenName: "",
        givenNameKana: "",
        purpose: "",
        purposeOption: "",
        experience: [],
        motivation: [],
        /** address */
        nationality: "",
        residenceEndAt: "",
        residenceStatus: "",
        isEmploymentRestriction: false,
        employmentRestriction: "",
        zipCode: "",
        prefecture: "",
        city: "",
        building: "",
        /** occupation */
        occupation: "",
        occupationOption: "",
        commerceRegion: "",
        commerceMerchandise: "",
        companyName: "",
        companyPhoneNumber: "",
        workplaceZipCode: "",
        workplacePrefecture: "",
        workplaceCity: "",
        workplaceBuilding: "",
        headquartersZipCode: "",
        headquartersPrefecture: "",
        headquartersCity: "",
        headquartersBuilding: "",
        department: "",
        /** asset */
        annualIncome: "",
        totalYens: "",
        totalBtc: "",
        totalEth: "",
        totalLtc: "",
        totalBch: "",
        totalXrp: "",
        tradingAgencies: [],
        /** bank */
        bankAccountHolder: "",
        bankAccountNumber: "",
        bankAccountType: 0,
        bankBranchCode: "",
        bankBranchName: "",
        bankCode: "",
        bankName: "",
        bankType: 0,
        // } as unknown as Omit<UpdateMeService, 'user' | 'requestType'>,
      } as any,
      requestType: "modificationRequest" as KycRequestCategoryType,
      isPeriodOfStay: null as boolean | null,
      periodOfStay: new Date().toISOString().substr(0, 10),
      isOpenDatePicker: false,
      statusOfResidence: StatusOfResidence,
      employmentRestrictions: EmploymentRestrictions,
      annualIncomeItems: [
        { value: 0, text: "０～２５０万円以下" },
        { value: 1, text: "２５０万円超５００万円以下" },
        { value: 2, text: "５００万円超７５０万円以下" },
        { value: 3, text: "７５０万円超１０００万円以下" },
        { value: 4, text: "１０００万円超" },
      ],
      occupationItems: [
        { text: "会社役員・団体役員" },
        { text: "会社員・団体職員" },
        { text: "公務員" },
        { text: "パート・アルバイト・派遣社員・契約社員" },
        { text: "個人事業主・自営業（海外との取引有り）※" },
        { text: "個人事業主・自営業（海外との取引無）" },
        { text: "投資家" },
        { text: "退職された方・無職の方" },
        { text: "その他" },
      ],
      purposeItems: [
        { text: "生計費" },
        { text: "事業資金" },
        { text: "その他" },
      ],
      experienceItems: [
        { text: "暗号資産取引" },
        { text: "外貨預金" },
        { text: "商品先物取引" },
        { text: "株式取引" },
        { text: "外国為替証拠金取引（FX）" },
        { text: "債券取引" },
        { text: "投資信託" },
        { text: "オプション取引" },
        { text: "投資経験なし" },
      ],
      motivationItems: [
        { text: "当社ホームページ" },
        { text: "ブログ・SNS" },
        { text: "ニュースサイト・WEB広告" },
        { text: "当社社員からの紹介" },
        { text: "ご利用者からの紹介" },
        { text: "イベント" },
      ],
      bankTypeItems: [
        { value: 1, text: "ゆうちょ銀行" },
        { value: 0, text: "その他" },
      ],
    };
  },

  watch: {
    "inputValues.building": {
      handler: function (val: string) {
        this.inputValues.building = this.replaceFullToHalf(val);
      },
    },
    "inputValues.workplaceBuilding": {
      handler: function (val: string) {
        this.inputValues.workplaceBuilding = this.replaceFullToHalf(val);
      },
    },
    "inputValues.headquartersBuilding": {
      handler: function (val: string) {
        this.inputValues.headquartersBuilding = this.replaceFullToHalf(val);
      },
    },
    zipCodeValues: {
      deep: true,
      handler(value) {
        this.inputValues.zipCode = value.zip1 + "-" + value.zip2;
      },
    },
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
    user: function (): UserResponse | null {
      return userModule.user;
    },
    nationItems: function () {
      if (Nationalities) {
        return Nationalities.map((n) => {
          return {
            text: n.companyjp,
            value: n.alpha3,
          };
        });
      } else {
        return [];
      }
    },
    card: function (): CardResponse | null {
      return cardModule.card;
    },
    isForeigner: function (): boolean {
      return userModule.isForeigner;
    },
    yuuchoBankAccountHolderRule(): string | boolean {
      if (
        this.inputValues.bankType === 1 &&
        !this.inputValues.bankAccountHolder
      ) {
        return "必須入力項目です";
      }
      if (
        this.inputValues.bankType === 1 &&
        this.inputValues.bankAccountHolder &&
        !this.inputValues.bankAccountHolder.match(/^[\u30a1-\u30fc\u3000]+$/)
      ) {
        return "カタカナで入力してください";
      }
      return true;
    },
    otherBankAccountHolderRule(): string | boolean {
      if (
        this.inputValues.bankType === 0 &&
        !this.inputValues.bankAccountHolder
      ) {
        return "必須入力項目です";
      }
      if (
        this.inputValues.bankType === 0 &&
        this.inputValues.bankAccountHolder &&
        !this.inputValues.bankAccountHolder.match(/^[\u30a1-\u30fc\u3000]+$/)
      ) {
        return "カタカナで入力してください";
      }
      return true;
    },
  },

  methods: {
    async getUser() {
      this.isLoading = true;
      const userId = LocalDataService.getUserId();
      if (userId) {
        await userModule.getUser().catch();
        this.setValues();
      } else {
        this.$router.push({ path: "/auth/signin" });
      }
      this.isLoading = false;
    },
    async getCard() {
      this.isLoading = true;
      await cardModule.getCard().catch();
      this.isLoading = false;
    },
    onClickReminderUpdate() {
      this.requestType = "remindedModificationRequest";
      this.updateUser();
    },
    onClickReminderNoUpdate() {
      this.requestType = "remindedConfirmationRequest";
      this.updateUser();
    },

    onChangeBankType() {
      this.inputValues.bankAccountHolder = "";
      this.inputValues.bankAccountNumber = "";
      this.inputValues.bankAccountType = 0;
      this.inputValues.bankBranchCode = "";
      this.inputValues.bankBranchName = "";
      this.inputValues.bankCode = "";
      this.inputValues.bankName = "";
    },
    onChangeResidenceStatus() {
      if (
        this.inputValues.residenceStatus !== "特別永住者" &&
        this.inputValues.residenceStatus !== "永住者"
      ) {
        this.isPeriodOfStay = true;
      } else {
        this.isPeriodOfStay = false;
        // this.request.residenceStatus = null;
        this.inputValues.residenceEndAt = null;
        this.inputValues.isEmploymentRestriction = null;
        this.inputValues.employmentRestriction = null;
      }
    },
    onChangeIsEmploymentRestriction() {
      if (!this.inputValues.isEmploymentRestriction) {
        this.inputValues.employmentRestriction = null;
      }
    },
    async updateUser() {
      this.isLoading = true;
      const userId = LocalDataService.getUserId();
      if (userId) {
        const response = await ApiUser.updateUser({
          requestType: this.requestType,
          ...this.inputValues,
        })
          .catch()
          .finally(() => {
            this.isLoading = false;
          });
        if (response) {
          alert("変更しました");
          this.$router.push({ name: "User", params: { isLogin: "true" } });
        }
      }
      this.isLoading = false;
    },
    formatDate(value: string): string {
      if (!value) {
        return "-";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
    setValues() {
      if (userModule.user) {
        /** user */
        this.inputValues.familyName = userModule.user.familyName;
        this.inputValues.givenName = userModule.user.givenName;
        this.inputValues.familyNameKana = userModule.user.familyNameKana;
        this.inputValues.givenNameKana = userModule.user.givenNameKana;
        /** purpose */
        this.inputValues.purpose = userModule.user.purpose;
        if (this.purposeItems.some((p) => p.text == userModule.user?.purpose)) {
          this.inputValues.purposeOption = userModule.user.purpose;
        } else {
          this.inputValues.purposeOption = "その他";
        }
        (this.inputValues.experience as any) = userModule.user.experience;
        (this.inputValues.motivation as any) = userModule.user.motivation;
        /** address */
        this.inputValues.nationality = userModule.user.nationality;
        this.inputValues.residenceEndAt = userModule.user.residenceEndAt
          ? dayjs(userModule.user.residenceEndAt).format("YYYY-MM-DD")
          : null;
        this.inputValues.residenceStatus = userModule.user.residenceStatus;
        this.inputValues.isEmploymentRestriction =
          userModule.user.isEmploymentRestriction;
        this.inputValues.employmentRestriction =
          userModule.user.employmentRestriction;
        /* zipCode */
        this.inputValues.zipCode = userModule.user.zipCode;
        const codes = userModule.user.zipCode?.split("-");
        if (codes?.[0]) {
          this.zipCodeValues.zip1 = codes[0];
        }
        if (codes?.[1]) {
          this.zipCodeValues.zip2 = codes[1];
        }

        this.inputValues.prefecture = userModule.user.prefecture;
        this.inputValues.city = userModule.user.city;
        this.inputValues.building = userModule.user.building;
        /** occupation */
        this.inputValues.occupation = userModule.user.occupation;
        if (
          this.occupationItems.some(
            (o) => o.text == userModule.user?.occupation
          )
        ) {
          this.inputValues.occupationOption = userModule.user.occupation;
        } else {
          this.inputValues.occupationOption = "その他";
        }
        this.inputValues.commerceRegion = userModule.user.commerceRegion;
        this.inputValues.commerceMerchandise =
          userModule.user.commerceMerchandise;
        this.inputValues.companyName = userModule.user.companyName;
        this.inputValues.companyPhoneNumber =
          userModule.user.companyPhoneNumber;
        this.inputValues.workplaceZipCode = userModule.user.workplaceZipCode;
        this.inputValues.workplacePrefecture =
          userModule.user.workplacePrefecture;
        this.inputValues.workplaceCity = userModule.user.workplaceCity;
        this.inputValues.workplaceBuilding = userModule.user.workplaceBuilding;
        this.inputValues.headquartersZipCode =
          userModule.user.headquartersZipCode;
        this.inputValues.headquartersPrefecture =
          userModule.user.headquartersPrefecture;
        this.inputValues.headquartersCity = userModule.user.headquartersCity;
        this.inputValues.headquartersBuilding =
          userModule.user.headquartersBuilding;
        this.inputValues.department = userModule.user.department;
        /** asset */
        this.inputValues.annualIncome = userModule.user.annualIncome;
        this.inputValues.totalYens = userModule.user.totalYens;
        this.inputValues.totalBtc = userModule.user.totalBtc;
        this.inputValues.totalEth = userModule.user.totalEth;
        this.inputValues.totalLtc = userModule.user.totalLtc;
        this.inputValues.totalBch = userModule.user.totalBch;
        this.inputValues.totalXrp = userModule.user.totalXrp;
        /** bank */
        this.inputValues.bankAccountHolder = userModule.user.bankAccountHolder;
        this.inputValues.bankAccountNumber = userModule.user.bankAccountNumber;
        this.inputValues.bankAccountType = userModule.user.bankAccountType;
        this.inputValues.bankBranchCode = userModule.user.bankBranchCode;
        this.inputValues.bankBranchName = userModule.user.bankBranchName;
        this.inputValues.bankCode = userModule.user.bankCode;
        this.inputValues.bankName = userModule.user.bankName;
        this.inputValues.bankType = userModule.user.bankType;
        (this.inputValues.tradingAgencies as any) =
          userModule.user.tradingAgencies;
        /** request type */
        // const today = dayjs();
        // const diff = dayjs(userModule.user.card?.cardExpiredAt).diff(today, 'month', true);
        // this.requestType = diff > 2 ? 'modificationRequest' : 'renewalRequest';
        if (this.card) {
          this.requestType = this.card.isCardUpdateRequired
            ? "renewalRequest"
            : "modificationRequest";
        }
      }
    },
    replaceFullToHalf(value: string): string {
      return value.replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      });
    },
  },
});
