
































































import Vue from "vue";
import { commonModule } from "@/store/viewModules/commonModule";
import { cardModule } from "@/store/dataModules/cardModule";
import ApiCard from "@/api/ApiCard.ts";
import ValidationMixin from "@/mixins/ValidationMixin";
import { CardResponse } from "gaia-api";

export default Vue.extend({
  name: "CardEdit",

  mixins: [ValidationMixin],

  props: {
    isLogin: {
      type: String,
    },
  },

  async mounted() {
    await this.getCard();
  },

  data() {
    return {
      inputValue: {
        password: "",
        password_confirm: "",
        currentPassword: "",
      },
      isFormValid: false,
    };
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
    card: function (): CardResponse | null {
      return cardModule.card;
    },
  },

  methods: {
    async getCard() {
      this.isLoading = true;
      await cardModule.getCard().catch();
      this.isLoading = false;
    },
    async updatePincode() {
      if (!this.passwordIsSame()) {
        alert("確認用のパスワードと異なります。再度入力して下さい。");
        return;
      }
      this.isLoading = true;
      if (this.card) {
        let input = null;
        if (this.card.isPasswordRegistered) {
          input = {
            cardNumber: this.card.cardNumber,
            password: this.inputValue.password,
            // currentPassword: this.inputValue.currentPassword,
          };
        } else {
          input = {
            cardNumber: this.card.cardNumber,
            password: this.inputValue.password,
          };
        }
        const response = await ApiCard.updateCard({
          ...input,
        }).catch();
        if (response) {
          alert("変更しました");
          this.$router.push({ name: "Card", params: { isLogin: "true" } });
        }
      }
      this.isLoading = false;
    },
    passwordIsSame(): boolean {
      return !!(this.inputValue.password === this.inputValue.password_confirm);
    },
  },
});
