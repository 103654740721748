import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index'
/* eslint-disable */

export interface AuthState {
  password: string;
}

@Module({ dynamic: true, store, name: 'auth', namespaced: true })
class AuthModule extends VuexModule implements AuthState {
  public password: string = '';

  @Mutation
  public setPassword(value: string) {
    this.password = value;
  }
}

export const authModule = getModule(AuthModule);
