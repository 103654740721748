
























































































































































































import Vue from "vue";
import { commonModule } from "@/store/viewModules/commonModule";
import { UserWalletListResponse, UserWalletResponse } from "gaia-api";
import { walletModule } from "@/store/dataModules/walletModule";
import LocalDataService from "@/service/LocalDataService";
import { userModule } from "@/store/dataModules/userModule";

export default Vue.extend({
  name: "Wallet",

  props: {
    isLogin: {
      type: String,
    },
  },

  data(): {
    isCreateDialog: boolean;
    isAlert: boolean;
    selectedWallet: UserWalletResponse | null;
  } {
    return {
      isCreateDialog: false,
      isAlert: false,
      selectedWallet: null,
    };
  },

  async mounted() {
    await this.getUser();
    await this.getWallets();
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
    listWallet: (): UserWalletListResponse => {
      return walletModule.listWallet;
    },
    innerWidht: (): number => {
      return window.innerWidth;
    },
  },

  methods: {
    async getUser() {
      this.isLoading = true;
      const userId = LocalDataService.getUserId();
      if (userId) {
        await userModule.getUser().catch(() => {
          this.isLoading = false;
        });
      } else {
        this.$router.push({ path: "/auth/signin" });
      }
      this.isLoading = false;
    },
    async getWallets() {
      this.isLoading = true;
      await walletModule.getWallet();
      this.isLoading = false;
    },
    getCurrencyText(type: "btc" | "eth" | "bch" | "ltc" | "ada"): string {
      if (type === "btc") {
        return "BTC(ビットコイン)";
      } else if (type === "eth") {
        return "ETH(イーサリアム)";
      } else if (type === "bch") {
        return "BCH(ビットコインキャッシュ)";
      } else if (type === "ltc") {
        return "LTC(ライトコイン)";
      } else if (type === "ada") {
        return "ADA(カルダノ)";
      } else {
        return "-";
      }
    },
    getStatusText(status: "rejected" | "requested" | "approved" | null) {
      if (status === "rejected") {
        return "否認";
      } else if (status === "requested") {
        return "審査中";
      } else if (status === "approved") {
        return "承認";
      } else if (status === null) {
        return "未登録";
      } else {
        return "-";
      }
    },
    getStatusColor(status: "rejected" | "requested" | "approved" | null) {
      if (status === "rejected") {
        return "red";
      } else if (status === "requested") {
        return "blue";
      } else if (status === "approved") {
        return "green";
      } else if (status === null) {
        return "grey";
      } else {
        return "grey";
      }
    },
    handleOnOpenDialog(wallet: UserWalletResponse) {
      this.selectedWallet = wallet;
      this.isCreateDialog = true;
    },
    async handleOnCreate() {
      if (this.selectedWallet) {
        this.isLoading = true;
        await walletModule.postWallet({
          currencyType: this.selectedWallet.currencyType,
          walletAddress: this.selectedWallet.walletAddress ?? "",
        });
        await this.getWallets();
        this.isLoading = false;
        this.isCreateDialog = false;
      }
    },
  },
});
