






















































import Vue from "vue";
import { commonModule } from "@/store/viewModules/commonModule";
import ValidationMixin from "@/mixins/ValidationMixin";
import LocalDataService from "@/service/LocalDataService";
import ApiPassword from "@/api/ApiPassword";

export default Vue.extend({
  name: "ChangePassword",

  mixins: [ValidationMixin],

  props: {
    isLogin: {
      type: String,
    },
  },

  data() {
    return {
      input: {
        oldPassword: "",
        newPassword: "",
      },
      isFormValid: false,
    };
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
  },

  methods: {
    async changePassword() {
      this.isLoading = true;
      const userId = LocalDataService.getUserId();
      if (userId) {
        const response = await ApiPassword.changePassword({
          oldPassword: this.input.oldPassword,
          newPassword: this.input.newPassword,
        }).catch();
        if (response) {
          alert("変更しました");
          this.$router.push({ name: "User", params: { isLogin: "true" } });
        }
      }
      this.isLoading = false;
    },
  },
});
