




















































import Vue from "vue";
import { commonModule } from "@/store/viewModules/commonModule";
import ValidationMixin from "@/mixins/ValidationMixin";
import ApiPassword from "@/api/ApiPassword";

export default Vue.extend({
  name: "ForgotPassword",

  mixins: [ValidationMixin],

  data() {
    return {
      input: {
        email: "",
      },
      isFormValid: false,
    };
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
  },

  methods: {
    async sendEmail() {
      this.isLoading = true;
      if (this.input.email) {
        const response = await ApiPassword.forgotPassword({
          email: this.input.email,
        }).catch();
        if (response) {
          alert("確認メールを送信しました");
          // push
        }
      }
      this.isLoading = false;
    },
  },
});
