import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#FFD600",
        secondary: "#424242",
        tertiary: "#8bc34a",
        lightgrey: "#fef1f2",
      },
    },
  },
});
