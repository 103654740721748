/* eslint-disable */
import ApiClient from "@/api/ApiClient";
import {
  ChangeEmailService,
  ConfirmAuthUserTokenRequestBody,
  ConfirmChangeEmailService,
  LoginAuthUserRequestBody,
} from "gaia-api";

export default class ApiAuth {
  public static async signIn(input: LoginAuthUserRequestBody) {
    const r = await ApiClient.post("/auth/signin", input);
    return r;
  }

  // メールアドレス変更申請
  public static async changeEmail(input: Omit<ChangeEmailService, "user">) {
    const r = await ApiClient.post("/change_mail", input);
    return r;
  }

  // メールアドレス変更認証
  public static async confirmChangeEmail(
    input: Omit<ConfirmChangeEmailService, "user">
  ) {
    const r = await ApiClient.put("/change_mail/confirmation", input);
    return r;
  }

  // sms
  public static async smsConfirm(input: ConfirmAuthUserTokenRequestBody) {
    const r = await ApiClient.post("/auth/token", input);
    return r;
  }

  // メールリンクユーザー有効化
  public static async completeRegistration(input: {
    token: string;
    email: string;
  }) {
    const r = await ApiClient.post("/auth/confirmation", input);
    return r;
  }
}
