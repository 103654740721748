



















import ApiAuth from "@/api/ApiAuth";
import { commonModule } from "@/store/viewModules/commonModule";
import Vue from "vue";
export default Vue.extend({
  name: "CompleteRegistration",

  async mounted() {
    this.isLoading = true;
    const url = new URL(location.href);
    const token = url.searchParams.get("token");
    const email = url.searchParams.get("email");
    if (token && email) {
      const response = await ApiAuth.completeRegistration({
        token,
        email,
      }).catch();
      if (response) {
        this.isLoading = false;
      }
    }
  },


  data() {
    return {};
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
  },
});
