import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import SignIn from "@/views/auth/SignIn.vue";
import SmsConfirm from "@/views/auth/SmsConfirm.vue";
import ChangePassword from "@/views/auth/ChangePassword.vue";
import ChangeEmail from "@/views/auth/ChangeEmail.vue";
import ChangeEmailConfirm from "@/views/auth/ChangeEmailConfirm.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ConfirmForgotPassword from "@/views/auth/ConfirmForgotPassword.vue";
import CompleteRegistration from "@/views/auth/CompleteRegistration.vue";
import TransactionList from "@/views/transaction/TransactionList.vue";
import TransactionReport from "@/views/transactionReport/TransactionReport.vue";
import User from "@/views/user/User.vue";
import UserEdit from "@/views/user/UserEdit.vue";
import Card from "@/views/card/Card.vue";
import CardEdit from "@/views/card/CardEdit.vue";
import RegisterLiquid from "@/views/liquid/RegisterLiquid.vue";
import Maintenance from "@/views/Maintenance.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Wallet from "@/views/wallet/Wallet.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/auth/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/auth/sms-confirm",
    name: "SmsConfirm",
    component: SmsConfirm,
  },
  {
    path: "/auth/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    props: true,
  },
  {
    path: "/auth/change-email",
    name: "ChangeEmail",
    component: ChangeEmail,
    props: true,
  },
  {
    path: "/auth/change-email/confirm",
    name: "ChangeEmailConfirm",
    component: ChangeEmailConfirm,
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/auth/forgot-password/confirm",
    name: "ConfirmForgotPassword",
    component: ConfirmForgotPassword,
  },
  {
    path: "/register/confirmation",
    name: "CompleteRegistration",
    component: CompleteRegistration,
  },
  {
    path: "/user",
    name: "User",
    component: User,
    props: true,
  },
  {
    path: "/user/edit",
    name: "UserEdit",
    component: UserEdit,
    props: true,
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: Wallet,
    props: true,
  },
  {
    path: "/transaction",
    name: "TransactionList",
    component: TransactionList,
    props: true,
  },
  {
    path: "/transaction/report",
    name: "TransactionReport",
    component: TransactionReport,
    props: true,
  },
  {
    path: "/card",
    name: "Card",
    component: Card,
    props: true,
  },
  {
    path: "/card/edit",
    name: "CardEdit",
    component: CardEdit,
    props: true,
  },
  {
    path: "/liquid",
    name: "RegisterLiquid",
    component: RegisterLiquid,
    props: true,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const blackList: string[] = [
  "User",
  "UserEdit",
  "Wallet",
  "TransactionList",
  "Card",
  "CardEdit",
  "ChangePassword",
  "ChangeEmail",
  "RegisterLiquid",
];

router.beforeEach((to, from, next) => {
  const maintenanceMode = process.env.VUE_APP_MAINTENANCE_MODE;

  if (maintenanceMode == "true" && to.path != "/maintenance") {
    next("/maintenance");
  }

  if (to && to.name && blackList.includes(to.name as any)) {
    // if (to.params && to.params.isLogin == "true") {
    const sessionToken = localStorage.getItem('ga_session_token')
    if (sessionToken) {
      next();
    } else {
      localStorage.clear();
      next({ path: "/auth/signin" });
    }
  } else {
    next();
  }
});

export default router;
