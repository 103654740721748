






















































import Vue from "vue";
import { commonModule } from "@/store/viewModules/commonModule";
import ValidationMixin from "@/mixins/ValidationMixin";
import ApiPassword from "@/api/ApiPassword";

export default Vue.extend({
  name: "ConfirmForgotPassword",

  mixins: [ValidationMixin],

  mounted() {
    const url = new URL(location.href);
    this.query.token = url.searchParams.get("token") ?? "";
    this.query.email = url.searchParams.get("email") ?? "";
  },

  data() {
    return {
      input: {
        password_0: "",
        password_1: "",
      },
      query: {
        token: "",
        email: "",
      },
      isFormValid: false,
    };
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
  },

  methods: {
    async changePassword() {
      if (this.input.password_0 != this.input.password_1) {
        alert("確認用のパスワードが異なります");
        return;
      }
      this.isLoading = true;
      if (this.query.token && this.query.email) {
        const response = await ApiPassword.confirmForgotPassword({
          token: this.query.token,
          email: this.query.email,
          password: this.input.password_1,
        }).catch();
        if (response) {
          alert("パスワードを変更しました。ログイン画面に戻ります");
          this.$router.push({ path: "/auth/signin" });
        }
      } else {
        alert("再度メールのリンクをクリックしてください");
      }
      this.isLoading = false;
    },
  },
});
