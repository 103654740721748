import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiTransaction from "@/api/ApiTransaction";
import {
  TransactionListForUserResponse,
  TransactionForUserResponse,
  UserTransactionReportListResponse,
} from "gaia-api";

// TODO: 使用しない
// interface TransactionHistoryEntity {
//   status: string;
//   wallet_address: string;
//   price: number;
//   amount: number;
//   currency_type: string;
//   place: string;
//   created_at: number;
//   transaction_id: number;
//   updated_at: number;
//   amount_jpy: number;
// }

export interface TransactionState {
  transactions: TransactionForUserResponse[];
  total: number;
}

@Module({ dynamic: true, store, name: "transaction", namespaced: true })
class TransactionModule extends VuexModule implements TransactionState {
  public transactions: TransactionForUserResponse[] = [];
  public total = 0;

  public transactionReports: UserTransactionReportListResponse | null = null;

  @Action({ rawError: true })
  public async listTransactions(input: { page?: number; perPage?: number }) {
    const response = await ApiTransaction.listTransactions(input).catch();
    if (response) {
      this.setTransactions(response.data);
      return response;
    }
  }

  @Action({ rawError: true })
  public async listTransactionReports() {
    const response = await ApiTransaction.listTransactionReports().catch();
    if (response) {
      this.setTransactionReports(response.data);
      return response;
    }
  }

  @Mutation
  public setTransactions(value: TransactionListForUserResponse) {
    this.transactions = value.items;
    this.total = value.total;
  }

  @Mutation
  public setTransactionReports(value: UserTransactionReportListResponse) {
    this.transactionReports = value;
  }
}

export const transactionModule = getModule(TransactionModule);
