








































































































import ApiTransaction from "@/api/ApiTransaction";
import { transactionModule } from "@/store/dataModules/transactionModule";
import { commonModule } from "@/store/viewModules/commonModule";
import dayjs from "dayjs";
import { TransactionForUserResponse, TransactionResponse } from "gaia-api";
import Vue from "vue";

export default Vue.extend({
  name: "TransactionList",

  props: {
    isLogin: {
      type: String,
    },
  },

  async mounted() {
    // if (!this.isLogin) {
    //   localStorage.clear();
    //   this.$router.push({ name: 'SignIn' });
    //   return;
    // }

    await this.listTransactions();
  },

  data() {
    return {
      page: 1,
      perPage: 20,
      statusItems: [
        { text: "取引開始", value: "initiated" },
        { text: "正常終了", value: "done" },
        { text: "過剰預かり", value: "over" },
        { text: "過小預かり", value: "less" },
        { text: "リスク預かり", value: "failed" },
        { text: "返金対応済み（正常終了）", value: "refunded" },
        { text: "取引なし", value: "timeout" },
        { text: "キャンセル", value: "canceled" },
        { text: "手数料以内の過剰預かり", value: "slightOver" },
        { text: "着金確認済み・検証中", value: "confirmedSuspended" },
        {
          text: "手数料以内の過剰預かり・検証中",
          value: "slightOverSuspended",
        },
        { text: "過剰預かり・検証中", value: "overSuspended" },
      ],
    };
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
    transactions: function (): TransactionForUserResponse[] {
      return transactionModule.transactions;
    },
    pageLength: function (): number {
      if (transactionModule.total) {
        return Math.ceil(transactionModule.total / this.perPage);
      } else {
        return 1;
      }
    },
  },

  methods: {
    async listTransactions() {
      this.isLoading = true;
      await transactionModule
        .listTransactions({
          page: this.page,
          perPage: this.perPage,
        })
        .catch();
      this.isLoading = false;
    },
    async downloadCsv() {
      this.isLoading = true;
      const response = await ApiTransaction.downloadCsv({
        page: this.page,
        perPage: this.perPage,
      }).catch();
      if (response && response.data.url) {
        const link = document.createElement("a");
        link.href = response.data.url;
        link.setAttribute("download", "GAIA_transactions.csv");
        document.body.appendChild(link);
        link.click();
      }
      this.isLoading = false;
    },
    formatDate(value: string): string {
      if (!value) {
        return "-";
      }
      return dayjs(value).format("YYYY/MM/DD HH:mm:ss");
    },
    getStatusText(value: string): string {
      return this.statusItems.find((s) => s.value == value)?.text ?? "";
    },
  },
});
