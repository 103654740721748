import ApiClient from "@/api/ApiClient";
import { UserWalletRequestBody } from "gaia-api";

export default class ApiWallet {
  public static async listWallet() {
    const r = await ApiClient.get(`/wallets`, {});
    return r;
  }

  public static async postWallet(wallet: UserWalletRequestBody) {
    const r = await ApiClient.post(`/wallets`, wallet);
    return r;
  }
}
