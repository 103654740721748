































import Vue from "vue";
import NavigationDrawer from "./components/organisms/NavigationDrawer.vue";
import { commonModule } from "@/store/viewModules/commonModule";
import { userModule } from "./store/dataModules/userModule";

export default Vue.extend({
  name: "App",

  components: {
    NavigationDrawer,
  },

  data() {
    return {
      drawer: true,
    };
  },

  computed: {
    isLoading: function (): boolean {
      return commonModule.isLoading;
    },
    loaderHeight: function (): number {
      return 5;
    },
    breakpoint: function (): any {
      return this.$vuetify.breakpoint;
    },
    isShowNavigationDrawer: function (): boolean {
      const hideList: string[] = [
        "SignIn",
        "SmsConfirm",
        "CompleteRegistration",
        "ForgotPassword",
        "ConfirmForgotPassword",
        // "RegisterLiquid",
        "Maintenance",
      ];
      if (this.$route.name && hideList.includes(this.$route.name)) {
        return false;
      } else {
        // return true;
        if (userModule.user) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
});
