




























































import Vue from "vue";
import { commonModule } from "@/store/viewModules/commonModule";
import { authModule } from "@/store/dataModules/authModule";
import ApiAuth from "@/api/ApiAuth";
import ValidationMixin from "@/mixins/ValidationMixin";

export default Vue.extend({
  name: "SignIn",

  mixins: [ValidationMixin],

  mounted() {
    const hostname = location.hostname;
    const env = hostname.split(".")[0];
    console.log(this.$vuetify.breakpoint);
  },

  data() {
    return {
      input: {
        email: "",
        password: "",
      },
      isFormValid: false,
    };
  },

  computed: {
    isLoading: {
      get(): boolean {
        return commonModule.isLoading;
      },
      set(value: boolean) {
        commonModule.setIsLoading(value);
      },
    },
  },

  methods: {
    async signIn() {
      this.isLoading = true;
      const response = await ApiAuth.signIn(this.input).catch(
        () => (this.isLoading = false)
      );
      if (response) {
        localStorage.setItem("ga_session_token", response.data.sessionToken);
        localStorage.setItem("ga_email", response.data.email);
        authModule.setPassword(this.input.password);
        alert("認証コードを送信しました");
        this.$router.push({ path: "/auth/sms-confirm" });
      }
      this.isLoading = false;
    },
  },
});
