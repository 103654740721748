























































import { userModule } from "@/store/dataModules/userModule";
import Vue from "vue";

export default Vue.extend({
  name: "NavigationDrawer",

  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      items: [
        { title: "ユーザー情報", to: "/user", name: "User" },
        { title: "暗号資産購入用ウォレット", to: "/wallet", name: "Wallet" },
        { title: "取引履歴", to: "/transaction", name: "TransactionList" },
        { title: "利用者カード", to: "/card", name: "Card" },
        {
          title: "取引報告書",
          to: "/transaction/report",
          name: "TransactionReport",
        },
        {
          title: "パスワード変更",
          to: "/auth/change-password",
          name: "ChangePassword",
        },
        {
          title: "メールアドレス変更",
          to: "/auth/change-email",
          name: "ChangeEmail",
        },
      ],
    };
  },

  computed: {
    breakpoint: function (): any {
      return this.$vuetify.breakpoint;
    },
    envName: function (): string {
      const hostname = location.hostname;
      const env = hostname.split(".")[0];
      if (env == "localhost" || env == "dev") {
        return "(dev環境)";
      } else if (env == "stg") {
        return "(Queueステージング環境)";
      } else if (env == "stg-gaia") {
        return "(GAIAステージング環境)";
      } else {
        return "";
      }
    },
  },

  methods: {
    goTop() {
      this.$router.push({ name: "User", params: { isLogin: "true" } }).catch();
    },
    logout() {
      const answer = confirm("ログアウトしますか？");
      if (answer) {
        userModule.setIsClickNotRightNow(false);
        localStorage.clear();
        this.$router.push({ path: "/auth/signin" });
      }
    },
    input(value: boolean) {
      this.$emit("input", value);
    },
    toWebExchange() {
      window.open("https://gaia-btm-web.com/login", "_blank");
    },
  },
});
